import palette from 'theme/palette'

export const MuiMenu = {
  styleOverrides: {
    paper: {
      '& > .MuiList-root': {
        borderTop: '1px solid #000000',
        paddingTop: 0
      },
      backgroundColor: palette.surface['container-high'],
      borderBottomLeftRadius: 16,
      borderBottomRightRadius: 16,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    root: {
      '& .MuiList-root': {
        'padding-bottom': 0
      },
    }
  }
}
