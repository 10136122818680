import { Route, Routes } from 'react-router-dom'

import App from '../App'
import Home from '../components/Home'
import LegalDocument from '../components/LegalDocument'
import TriagePage from '../pages/triage/TriagePage'
import EmergencyPage from '../pages/triage/emergency/EmergencyPage'
import NonEmergencyPage from '../pages/triage/non-emergency/NonEmergencyPage'
import EmergencyPersonPage from '../pages/triage/emergency/[person]/EmergencyPersonPage'
import NonEmergencyPersonPage from '../pages/triage/non-emergency/[person]/NonEmergencyPersonPage'
import EmergencyQuestionDisplay from '../pages/triage/emergency/[person]/[question]/EmergencyQuestionDisplay'
import Resources from '../components/Resources/Resources'
import NonEmergencyQuestionDisplay from '../pages/triage/non-emergency/[person]/[question]/NonEmergencyQuestionDisplay'
import ResourcePage from '../pages/resource/ResourcePage'
import Helper from '../pages/helper'

const AppRoutes = school => (
  <Routes>
    <Route element={<App />} />
    {/* Non Authenticated Routes */}
    <Route element={<Home school={school} />} path="/" />
    <Route
      element={<LegalDocument key="1" type="accessibility" />}
      path="/accessibility"
    />
    <Route
      element={<LegalDocument key="2" type="terms" />}
      path="/terms-and-conditions"
    />
    <Route
      element={<LegalDocument key="3" type="privacy" />}
      path="/privacy-policy"
    />
    <Route element={<TriagePage school={school} />} path="/triage" />
    <Route
      element={<EmergencyPage school={school} />}
      path="/triage/emergency"
    />
    <Route
      element={<NonEmergencyPage school={school} />}
      path="/triage/non-emergency"
    />
    <Route
      element={<EmergencyPersonPage school={school} />}
      path="/triage/emergency/:person"
    />
    <Route
      element={<NonEmergencyPersonPage school={school} />}
      path="/triage/non-emergency/:person"
    />
    <Route
      element={<EmergencyQuestionDisplay school={school} />}
      path="/triage/emergency/:person/:question"
    />
    <Route
      element={<NonEmergencyQuestionDisplay school={school} />}
      path="/triage/non-emergency/:person/:question"
    />
    <Route
      element={<Resources school={school} />}
      path="/triage/emergency/:person/resources"
    />
    <Route
      element={<Resources school={school} />}
      path="/triage/non-emergency/:person/resources"
    />
    <Route element={<ResourcePage school={school} />} path="/resource/:id" />
    <Route element={<Helper school={school} />} path="/helper" />
  </Routes>
)

export default AppRoutes
