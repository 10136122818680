/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import { Box, Button, Stack } from '@mui/material'

const Header = ({ isHome, school }) => {
  return (
    <Stack
      sx={
        isHome
          ? {
              alignItems: 'center',
              position: 'absolute',
              pt: { xs: 1.5, md: 3 },
              px: { xs: 1.5, md: 3 },
              top: 0,
              width: 1,
              zIndex: 5,
            }
          : {
              alignItems: 'center',
              backgroundColor: 'surface.surface',
              m: '0 auto',
              position: 'sticky',
              pt: { xs: 1.5, md: 3 },
              px: { xs: 1.5, md: 3 },
              top: 0,
              width: 1,
              zIndex: 5,
            }
      }
    >
      <Box
        alignItems={'center'}
        component="header"
        display="flex"
        justifyContent={'space-between'}
        sx={{
          backgroundColor: 'surface.container-low',
          borderRadius: 6,
          boxShadow: {
            xs: 0,
            md: '0px 0px 16px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 1px rgba(0, 0, 0, 0.08)',
          },
          position: 'relative',
          width: 1,
        }}
      >
        <Button aria-label="Find Help Now Home Link" href="/" variant="text">
          <Box component="span" sx={{ cursor: 'pointer', pl: 2, pt: 1 }}>
            <Box
              alt="Help Compass Logo"
              component="img"
              height={32}
              src="/images/triage/HC-logo-black.svg"
            />
          </Box>
        </Button>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '75px',
            pr: 2,
          }}
        >
          <Box
            alt={school.name + ' Logo'}
            component="img"
            src={school.triage_logo}
            width={{ xs: '32px', lg: '225px' }}
          />
        </Box>
      </Box>
    </Stack>
  )
}

Header.propTypes = {
  isHome: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
}

export default Header
