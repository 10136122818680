import { useParams } from 'react-router-dom'
import HelperDetail from '../components/HelperDetail'

const Helper = () => {
  const { resourcesVisited } = useParams()

  return <HelperDetail resourcesVisited={resourcesVisited} />
}

export default Helper
