import sendAnalytics from '../../utils/sendAnalytics'
import PropTypes from 'prop-types'
import ResourceFieldDisplay from './ResourceFieldDisplay'
import Button from '@mui/material/Button'

function ResourceField({
  icon,
  isMultiline,
  text,
  text2,
  link,
  resourceAnalytics,
  isPhoneNumber,
  isEmail,
}) {
  if (link) {
    return (
      <Button
        href={isPhoneNumber ? `tel:${link}` : isEmail ? `mailto:${link}` : link}
        LinkComponent="a"
        onClick={() => {
          sendAnalytics(
            window.location,
            'resource-field-link',
            { ...resourceAnalytics, link, text },
            'resource'
          )
        }}
        sx={{
          backgroundColor: 'surface.container-high',
          borderRadius: 50,
          px: 0.75,
          py: 0.25,
          textTransform: 'capitalize',
          width: 'fit-content',
        }}
        target="_blank"
        variant="text"
      >
        <ResourceFieldDisplay
          icon={icon}
          isMultiline={isMultiline}
          text={text}
          text2={text2}
        />
      </Button>
    )
  } else {
    return <ResourceFieldDisplay icon={icon} text={text} />
  }
}

ResourceField.propTypes = {
  icon: PropTypes.object,
  isEmail: PropTypes.bool,
  isMultiline: PropTypes.bool,
  isPhoneNumber: PropTypes.bool,
  link: PropTypes.string,
  resourceAnalytics: PropTypes.object,
  school: PropTypes.object,
  text: PropTypes.string,
  text2: PropTypes.string,
}

export default ResourceField
