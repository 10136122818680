import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { triageApi } from '../api/triageApi'
import history from './historySlice'
// import { createWrapper } from 'next-redux-wrapper'

const reducer = { history, [triageApi.reducerPath]: triageApi.reducer}

// Removed combined reducers and preloaded state, could optionally add back in
// the preloadedState if we want to use in testing
export const store = 
// preloadedState =>
  configureStore({
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        triageApi.middleware
      ),
    // preloadedState,
    reducer,
  })
