import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'

const ResourcesSkeletonLoader = () => {
  return (
    <Grid container spacing={2}>
      {[0, 1, 2, 3].map((item, index) => (
        <Grid item key={index} sm={4} xs={12}>
          <Skeleton
            height={318}
            sx={{ borderRadius: 2 }}
            variant="rectangular"
            width={{ xs: '90%', lg: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default ResourcesSkeletonLoader
