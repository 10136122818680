/* eslint-disable sort-keys */
import { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import sendAnalytics from '../../utils/sendAnalytics'
import SingleSelectQuestion from './QuestionTypes/SingleSelect'
import MultiSelectQuestion from './QuestionTypes/MultiSelect'
import NeedsQuestion from './QuestionTypes/Needs'
import LetsGoQuestion from './QuestionTypes/LetsGo'
import QuestionNavSidebar from './QuestionNavSidebar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { QuestionContext } from '../../contexts/questionContext'
import { getFormattedResourcesPath } from '../../utils/getFormattedResourcesPath'
import {
  always,
  append,
  dropLast,
  equals,
  ifElse,
  join,
  not,
  or,
  split,
} from 'ramda'
import {
  QUESTION_TYPE_LETSGO,
  QUESTION_TYPE_MULTISELECT,
  QUESTION_TYPE_NEEDS,
  QUESTION_TYPE_SINGLESELECT,
} from '../../utils/questionTypes'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import Icon from 'components/Icon/Icon'

const progressStepText = [
  'Who needs help?',
  'What kind of help?',
  'What circumstances?',
]

const Question = ({
  type,
  school,
  progressStep,
  triage_logo = null,
  questionObject,
  categories = [],
  needTitles = null,
  isAccessCriteriaQuestion = false,
  isFirst = false,
  questionDescription = null,
  needsAnalytics,
  goToResources = false,
  nextQuestionId = null,
  onBackCallback = () => {},
  onNextCallback = () => {},
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { previousAnswers, previousNeeds, seeAllTopics } =
    useContext(QuestionContext)
  //State///////////////////////////////////////////////////////////////////////
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
  const navigate = useNavigate()

  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  //Vars////////////////////////////////////////////////////////////////////////
  const showNextButton = type !== QUESTION_TYPE_SINGLESELECT
  const analytics = {
    ...needsAnalytics,
    all_topics: false,
    categories,
    multiSelectIds: [],
    questionObject,
  }

  // this handles applying the routing info to the url
  const handleNextButtonClick = () => {
    setDisplayErrorMessage(false)
    if (goToResources) {
      navigate({
        pathname: getFormattedResourcesPath(location.pathname),
        search: `?${createSearchParams({
          criteria: searchParams.get('criteria') ?? '',
          multiselect: searchParams.get('multiselect') ?? '',
          needs: searchParams.get('needs') ?? '',
        })}`,
      })
      if (type === QUESTION_TYPE_NEEDS) {
        sendAnalytics(
          location.pathname,
          'next',
          { ...analytics, all_topics: seeAllTopics, needs: previousNeeds },
          'needs-page'
        )
      } else {
        sendAnalytics(
          location.pathname,
          'next',
          {
            ...analytics,
            all_topics: previousAnswers?.[questionObject?.id]?.selectAll
              ? true
              : false,
            answers: previousAnswers?.[questionObject?.id]?.answers,
            needs: previousNeeds,
          },
          'multi-select-page'
        )
      }
      return
    }
    switch (type) {
      case QUESTION_TYPE_NEEDS:
      case QUESTION_TYPE_MULTISELECT: {
        onNextCallback()
        setSearchParams(searchParams)
        if (searchParams.get('needs').length < 1) {
          setDisplayErrorMessage(true)
          break
        }
        // needs or criteria params are added to the navigate.query inside the
        // specific Question component (NeedsQuestion for needs, MultiSelect for criteria)
        // and then applied here
        let new_path = dropLast(1, split('/', location.pathname))
        // this sucks but because dynamic questions may or may not be present before the needs
        // question we have to check if the last question answered was the me/them question
        // so the isHelper check works at all times
        const last_path = split('/', location.pathname).slice(-1)[0]
        if (or(equals(last_path, 'them'), equals(last_path, 'me'))) {
          new_path = split('/', location.pathname)
        }
        const params = createSearchParams({
          criteria: searchParams.get('criteria') ?? '',
          multiselect: searchParams.get('multiselect') ?? '',
          needs: searchParams.get('needs') ?? '',
        })
        navigate({
          pathname: join('/', append(nextQuestionId, new_path)),
          search: `?${params}`,
        })
        if (type === QUESTION_TYPE_NEEDS) {
          sendAnalytics(
            location.pathname,
            'next',
            { ...analytics, all_topics: seeAllTopics, needs: previousNeeds },
            'needs-page'
          )
        } else {
          sendAnalytics(
            location.pathname,
            'next',
            {
              ...analytics,
              all_topics: previousAnswers?.[questionObject?.id]?.selectAll
                ? true
                : false,
              answers: previousAnswers?.[questionObject?.id]?.answers,
              needs: previousNeeds,
            },
            'multi-select-page'
          )
        }
        break
      }
      case QUESTION_TYPE_LETSGO:
        // todo - this route doesnt exist atm
        navigate(location.pathname + '/go')
        break
      default:
        break
    }
  }

  const handleBackButton = () => {
    if (type === QUESTION_TYPE_NEEDS || type === QUESTION_TYPE_MULTISELECT) {
      sendAnalytics(
        location.pathname,
        'back-nav',
        {
          ...analytics,
          all_topics: previousAnswers?.[questionObject?.id]?.selectAll,
          answers: previousAnswers?.[questionObject?.id]?.answers,
          back_nav: true,
          needs: previousNeeds,
        },
        type === QUESTION_TYPE_NEEDS ? 'needs-page' : 'multi-select-page'
      )
    } else {
      sendAnalytics(location.pathname, 'back-nav', analytics)
    }
    onBackCallback()
    navigate(-1)
  }

  const renderNavButton = ifElse(
    equals(true),
    always(
      <Button
        onClick={() => navigate('/')}
        startIcon={
          <Icon fill={true} sx={{ fontSize: 12 }}>
            home
          </Icon>
        }
        sx={{ width: 'fit-content' }}
        variant="back-button"
      >
        return home
      </Button>
    ),
    always(
      <Button
        onClick={() => handleBackButton()}
        startIcon={<Icon>arrow_back</Icon>}
        sx={{ width: 'fit-content' }}
        variant="back-button"
      >
        go back
      </Button>
    )
  )

  //Return//////////////////////////////////////////////////////////////////////
  return (
    <Grid container sx={{ bgcolor: 'surface.surface', mb: 0 }}>
      <Grid item sm={3} xs={12}>
        <QuestionNavSidebar
          handleBackButton={handleBackButton}
          progressStep={progressStep}
          progressStepText={progressStepText}
        />
      </Grid>
      <Grid
        item
        md={9}
        sm={9}
        sx={{
          borderBottomLeftRadius: { xs: 'unset', lg: '5% 50%' },
          borderTopLeftRadius: { xs: 'unset', lg: '5% 50%' },
          minHeight: { xs: '80vh', lg: 'unset' },
        }}
        xs={12}
      >
        <Stack
          spacing={2}
          sx={{
            mt: { xs: 3, lg: 7 },
            pl: { xs: 'unset', lg: 10 },
            px: { xs: 2, lg: 'unset' },
            width: { xs: '100%', lg: '70%' },
          }}
        >
          {not(isMobile) && renderNavButton(isFirst)}
          {type === QUESTION_TYPE_SINGLESELECT ? (
            <SingleSelectQuestion
              analytics={analytics}
              goToResources={goToResources}
              isAccessCriteriaQuestion={isAccessCriteriaQuestion}
              isFirstQuestion={equals(1, progressStep)}
              // must be keyed in order for dom to re render
              key={questionObject.id}
              nextQuestionId={nextQuestionId}
              onNextCallback={onNextCallback}
              questionDescription={questionDescription}
              questionObject={questionObject}
              triage_logo={triage_logo}
            />
          ) : null}

          {type === QUESTION_TYPE_MULTISELECT ? (
            <MultiSelectQuestion
              // must be keyed in order for dom to re render
              key={questionObject.id}
              questionDescription={questionDescription}
              questionObject={questionObject}
              school={school}
              searchParams={searchParams}
              triage_logo={triage_logo}
            />
          ) : null}

          {type === QUESTION_TYPE_NEEDS ? (
            <NeedsQuestion
              categories={categories}
              displayErrorMessage={displayErrorMessage}
              goToResources={goToResources}
              questionDescription={questionDescription}
              searchParams={searchParams}
              setDisplayErrorMessage={setDisplayErrorMessage}
              triage_logo={triage_logo}
            />
          ) : null}

          {type === QUESTION_TYPE_LETSGO ? (
            <LetsGoQuestion
              needTitles={needTitles}
              questionDescription={questionDescription}
              triage_logo={triage_logo}
            />
          ) : null}
          <br />

          {showNextButton && (
            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
              <Button
                onClick={handleNextButtonClick}
                sx={{ fontSize: '18px', px: '24px', py: 1 }}
                variant="home"
              >
                {type === QUESTION_TYPE_LETSGO ? "Ok let's go" : 'Next'}
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}

Question.propTypes = {
  categories: PropTypes.array,
  goToResources: PropTypes.bool,
  isAccessCriteriaQuestion: PropTypes.bool,
  isFirst: PropTypes.bool,
  needTitles: PropTypes.array,
  needsAnalytics: PropTypes.object,
  nextQuestionId: PropTypes.number,
  onBackCallback: PropTypes.func,
  onNextCallback: PropTypes.func,
  progressStep: PropTypes.number.isRequired,
  questionDescription: PropTypes.string,
  questionObject: PropTypes.object.isRequired,
  school: PropTypes.object,
  triage_logo: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default Question
