import React from 'react'
import PropTypes from 'prop-types'
import { getNeedsFromCategories } from '../utils/getNeedsFromCategories'
import { getCriteriaFromQuestions } from '../utils/getCriteriaFromQuestions'
import { getNeedsForDisplay } from '../utils/getNeedsForDisplay'
import { equals, isEmpty, map, or, split } from 'ramda'

const ResourcesContext = React.createContext({})

const ResourcesContextProvider = ({
  children,
  categories,
  criteria,
  questions,
  needs,
  isHelper,
  isEmergency,
}) => {
  // this is used to house data we may need for the resources
  // page. currently it only houses info for analytics
  // the actual resources will be held in the rtquery reducer
  const needsArray = map(need => parseInt(need), split(',', needs))
  const needTitles = getNeedsFromCategories(categories).reduce(
    (titles, need) =>
      needsArray.includes(need.id) ? titles.concat(need.name) : titles,
    []
  )
  const criteriaArray = map(c => parseInt(c), split(',', criteria))
  const accessCriteria = getCriteriaFromQuestions(questions).reduce(
    (titles, criteria) =>
      criteriaArray.includes(criteria.id)
        ? titles.concat(criteria.name)
        : titles,
    []
  )
  const baseResourceAnalytics = {
    accessCriteria,
    isEmergency,
    needTitles,
  }
  const needsCopy = or(equals(needs, 'none'), isEmpty(needs))
    ? `${isHelper ? 'their' : 'your'} needs`
    : getNeedsForDisplay(needTitles)

  return (
    <ResourcesContext.Provider
      value={{
        baseResourceAnalytics,
        isEmergency,
        isHelper,
        needsCopy,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  )
}

ResourcesContextProvider.propTypes = {
  categories: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  criteria: PropTypes.string,
  isEmergency: PropTypes.bool,
  isHelper: PropTypes.bool,
  needs: PropTypes.string,
  questions: PropTypes.array,
}

export { ResourcesContextProvider, ResourcesContext }
