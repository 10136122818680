import { cond, equals, always, lt } from 'ramda'

export const getNeedsForDisplay = (needsTitlesArray) => {
  const needs = needsTitlesArray
  const needsString = cond([
    [equals(1), always(needs[0])],
    [equals(2), always(needs[0] + ' and ' + needs[1])],
    [
      lt(2), always(
      [
        needs.slice(0, needs.length - 1).join(', '),
        needs[needs.length - 1],
      ].join(', and '))
    ]
  ])
  return needsString(needs.length)
}
