import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const YouLogoBadge = ({ schoolLogo, sx }) => {
  const YouLogoImg = '/images/triage/you-logo-short-white.png'

  return (
    <Box
      sx={{
        background: theme => theme.palette.school.primary,
        display: 'flex',
        px: 1,
        py: 2,
        ...sx,
      }}
    >
      <img
        alt="You Logo"
        src={YouLogoImg}
        style={{
          borderRight: '4px solid white',
          height: '30px',
          marginRight: '8px',
          paddingRight: '8px',
        }}
      />
      <img alt="School Logo" src={schoolLogo} style={{ height: '30px' }} />
    </Box>
  )
}

YouLogoBadge.propTypes = {
  schoolLogo: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

YouLogoBadge.defaultProps = {
  sx: {},
}

export default YouLogoBadge
