import { useGetLegalStatementsQuery } from '../api/triageApi'
import { getSchoolConfig } from '../utils/sessionStorage/schoolConfig'
import { cond, equals, always } from 'ramda'
import PropTypes from 'prop-types'
import ResourceSkeletonLoader from './Resource/ResourceSkeletonLoader'
import Quicklinks from './Quicklinks'

const LegalDocument = ({ type }) => {
  const school = getSchoolConfig()

  // Dynamically gets document id based on which route is calling the component
  const getDocumentId = cond([
    [equals('privacy'), always(school.privacy_policy_id)],
    [equals('terms'), always(school.terms_and_conditions_id)],
    [equals('accessibility'), always(school.triage_accessibility_id)],
  ])

  const { data: document, isFetching } = useGetLegalStatementsQuery(
    getDocumentId(type)
  )

  if (isFetching || school == null) {
    return <ResourceSkeletonLoader />
  }

  return (
    <div
      style={{
        margin: '0 auto',
        width: '90%',
      }}
    >
      {document && document.statement ? (
        <Quicklinks headingElement="h3" rawHtml={document?.statement} />
      ) : null}
    </div>
  )
}

LegalDocument.propTypes = {
  type: PropTypes.string,
}

export default LegalDocument
