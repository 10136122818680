import mixpanel from 'mixpanel-browser'
import {prop} from 'ramda'
import { getSchoolConfig } from './sessionStorage/schoolConfig'

async function trackEvent (type, params) {
  const school = await getSchoolConfig()
  const baseParams = {
    'Dev Environment': process.env.REACT_APP_PUBLIC_ENV,
    Channel: document.referrer,
    'Institution Id': [prop('id', school)],
    'School Id': prop('id', school),
    'School Name': prop('name', school),
  }
  mixpanel.track(type, { ...baseParams, ...params })
  return
}

export default trackEvent
