import Icon from 'components/Icon/Icon'
import { always, cond, equals, T } from 'ramda'

export const getPrivacyIcon = (privacyLabel) => {
    const privacyIcon = cond([
        [
          equals('confidential'),
          always(
            <Icon fill sx={{ backgroundColor: 'surace.on-surface', fontSize: 14 }}>
              lock
            </Icon>),
        ],
        [
          equals('private'),
          always(
            <Icon fill sx={{ backgroundColor: 'surace.on-surface', fontSize: 14 }}>
              visibility_off
            </Icon>
          ),
        ],
        [
          equals('non-private'),
          always(
            <Icon fill sx={{ backgroundColor: 'surace.on-surface', fontSize: 14 }}>
              visibility
            </Icon>
          ),
        ],
        [
          equals('anonymous'),
          always(
            <Icon fill sx={{ backgroundColor: 'surace.on-surface', fontSize: 14 }}>
              privacy_tip
            </Icon>
          ),
        ],
        [T, always(<Icon fill sx={{ backgroundColor: 'surace.on-surface', fontSize: 14 }}>
              lock
            </Icon>)],
      ])
    return privacyIcon(privacyLabel)
}