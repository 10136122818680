import React, { useEffect, useState } from 'react'
import { FormControl, ListItemText, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import Icon from 'components/Icon/Icon'

export const SORT_OPTION_MOST_RELEVANT = 'SORT_OPTION_MOST_RELEVANT'
export const SORT_OPTION_ALPHABETIC = 'SORT_OPTION_ALPHABETIC'
export const SORT_OPTION_PRICE = 'SORT_OPTION_PRICE'
export const SORT_OPTION_ON_OFF_CAMPUS = 'SORT_OPTION_ON_OFF_CAMPUS'

export const sortOptions = [
  SORT_OPTION_MOST_RELEVANT,
  SORT_OPTION_ALPHABETIC,
  SORT_OPTION_PRICE,
  SORT_OPTION_ON_OFF_CAMPUS,
]
export const labels = {
  SORT_OPTION_ALPHABETIC: 'A-Z',
  SORT_OPTION_MOST_RELEVANT: 'Most Relevant',
  SORT_OPTION_ON_OFF_CAMPUS: 'On–Off Campus',
  SORT_OPTION_PRICE: 'Free–$$',
}

const SortMenu = ({ hasOnCampus, onChange }) => {
  const [selectedSortOption, setSelectedSortOption] = useState(0)
  const [availableSortOptions, setAvailableSortOptions] = useState(sortOptions)

  useEffect(() => {
    if (equals(false, hasOnCampus)) {
      setAvailableSortOptions([
        SORT_OPTION_MOST_RELEVANT,
        SORT_OPTION_ALPHABETIC,
        SORT_OPTION_PRICE,
      ])
    }
  }, [hasOnCampus])

  const handleClick = e => {
    const index = e.target.value
    setSelectedSortOption(index)
    onChange(sortOptions[index])
  }

  return (
    <FormControl variant="filled">
      <Select
        aria-label={
          'Sort menu ' + labels[sortOptions[selectedSortOption]] + ' active'
        }
        disableUnderline={true}
        hiddenLabel={true}
        id="sort-select"
        label={labels[sortOptions[selectedSortOption]]}
        onChange={handleClick}
        renderValue={() => labels[sortOptions[selectedSortOption]]}
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          width: { xs: '100%', md: '180px' },
        }}
        value={selectedSortOption}
      >
        <MenuItem sx={{ mt: 0.5 }}>Sort By</MenuItem>
        {availableSortOptions.map((option, index) => (
          <MenuItem
            aria-label={'Sort: ' + labels[sortOptions[index]]}
            key={index}
            value={index}
          >
            <ListItemText primary={labels[sortOptions[index]]} />
            {equals(selectedSortOption, index) && <Icon>check</Icon>}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SortMenu.propTypes = {
  hasOnCampus: PropTypes.bool,
  onChange: PropTypes.func,
}

export default SortMenu
