import palette from '../palette'

export const MuiFilledInput = {
  styleOverrides: {
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 100px ${palette.surface.container} inset`,
        WebkitTextFillColor: palette.surface.onSurface,
        transition: 'background-color 9999s ease-in-out 0s'
      },
      backgroundColor: palette.surface.container,
      borderRadius: 16
    },
    notchedOutline: {
      border: 'none'
    },
    root: {
      '&.Mui-error': {
        border: '1px solid',
        borderColor: palette.error.main
      },
      '@media (max-width:600px)': {
        // This prevens iPhone auto zooming on text field
        fontSize: 16
      },
      backgroundColor: palette.surface.container,
      borderRadius: 16,
      color: palette.surface.onSurface,
    }
  }
}
