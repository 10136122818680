import palette from '../palette'

export const MuiSelect = {
  styleOverrides: {
    icon: {
      color: palette.surface['on-surface']
    },
    select: {
      '&[aria-expanded="true"]': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: 'all .3s ease-in-out'
      },
    }
  }
}
