import Resource from '../../components/Resource/Resource'
import { useGetResourceQuery } from '../../api/triageApi'
import { getOrgDomain } from '../../utils/sessionStorage/orgDomain'
import { equals } from 'ramda'
import { useParams } from 'react-router-dom'

const ResourcePage = () => {
  const { id } = useParams()
  // we need to check against the string 'undefined'
  // because getOrgDomain fetches from sessionStorage
  // which stores the value as a string
  const { data: resource } = useGetResourceQuery(id)
  if (equals(getOrgDomain(), 'undefined')) {
    return null
  }

  // resource component handles loading state
  return <Resource resource={resource} />
}

export default ResourcePage
