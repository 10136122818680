import React, { useState } from 'react'
import PropTypes from 'prop-types'

const QuestionContext = React.createContext({})

const QuestionContextProvider = ({ children }) => {
  // these are used to hold the needs selected in the NeedsQuestion as state
  // so that if the user navigates back to the NeedsQuestion from the results
  // without going through the question flow over again, their previous
  // selections will be applied as the default state.
  const [previousNeeds, setPreviousNeeds] = useState([])
  const [seeAllTopics, setSeeAllTopics] = useState(false)
  /**
   * Context used to hold prior responses of dynamic questions for when user navigates
   * backwards through questions
   */
  const [previousAnswers, setPreviousAnswers] = useState({})

  const resetContext = () => {
    setPreviousNeeds([])
    setSeeAllTopics(false)
    setPreviousAnswers({})
  }

  return (
    <QuestionContext.Provider
      value={{
        previousAnswers,
        previousNeeds,
        resetContext,
        seeAllTopics,
        setPreviousAnswers,
        setPreviousNeeds,
        setSeeAllTopics,
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}

QuestionContextProvider.propTypes = {
  children: PropTypes.any,
}

export { QuestionContextProvider, QuestionContext }
