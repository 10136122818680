import PropTypes from 'prop-types'
import { deepmerge } from '@mui/utils'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '.'
import palette from './palette'

const MuiThemeProvider = ({ children, school }) => {
  // Any components that include school color overrides must be here
  const schoolTheme = {
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          contained: {
            background: `linear-gradient(-180deg, ${school.gradient_start_color} 0%, ${school.gradient_stop_color} 99%)`,
            color: theme.palette.primary.main,
          },
          outlined: {
            borderColor: theme.palette.buttons.primary['background-default'],
            borderRadius: '50px',
            color: theme.palette.buttons.primary['background-default'],
            width: 'fit-content',
          },
          skipLink: {
            '&:focus': {
              left: 0,
            },
            // eslint-disable-next-line sort-keys
            background: school.primary_color,
            color: 'white',
            left: -222,
            p: '6px 15px',
            position: 'absolute',
            top: 72,
            transition: 'left 0.5s ease-out',
            zIndex: '5',
          },
          text: {
            color: 'black',
          },
        },
        variants: [
          {
            props: { variant: 'home' },
            style: {
              '&:hover': {
                backgroundColor: palette.buttons.primary['background-hover'],
              },
              ':focus': {
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              },
              backgroundColor: palette.buttons.primary['background-default'],
              borderRadius: '50px',
              boxShadow: '1px 2px 4px 0 rgba(79,79,79,0.25)',
              color: palette.buttons.primary['on-surface'],
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              textTransform: 'capitalize',
              width: 'fit-content',
            },
          },
          {
            props: { variant: 'single-select' },
            style: {
              ':focus': {
                backgroundColor: palette.surface['container-high'],
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              },
              ':hover': {
                backgroundColor: palette.buttons.tonal['background-hover'],
              },
              backgroundColor: palette.buttons.tonal['background-default'],
              borderRadius: '50px',
              color: palette.buttons.tonal['on-surface'],
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              textTransform: 'capitalize',
              width: 'fit-content',
            },
          },
          {
            props: { variant: 'back-button' },
            style: {
              '&:focus': {
                backgroundColor: palette.buttons.text['background-focused'],
              },
              '&:hover': {
                backgroundColor: palette.buttons.text['background-hover'],
              },
              alignItems: 'center',
              borderRadius: '50px',
              color: palette.buttons.text['on-surface'],
              display: 'flex',
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              height: 'fit-content',
              letterSpacing: 0.5,
              px: 0.75,
              py: 0.25,
              textTransform: 'capitalize',
              width: 'fit-content',
            },
          },
          {
            props: { variant: 'copy-link' },
            style: {
              '&:focus': {
                backgroundColor: palette.buttons.text['background-focused'],
              },
              '&:hover': {
                backgroundColor: palette.buttons.text['background-hover'],
              },
              alignItems: 'center',
              backgroundColor: palette.buttons.outline['background-default'],
              border: '1px solid #2B6772',
              borderRadius: '50px',
              color: palette.buttons.text['on-surface'],
              display: 'flex',
              fontFamily: 'mark-ot-bold',
              fontSize: 14,
              letterSpacing: 0.5,
              lineHeight: 'normal',
              px: 0.75,
              py: 0.25,
              textTransform: 'capitalize',
              width: 'fit-content',
            },
          },
          {
            props: { variant: 'multiselect' },
            style: {
              ':focus': {
                backgroundColor: palette.surface['container-high'],
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              },
              ':hover': {
                backgroundColor: palette.surface.container,
              },
              backgroundColor: palette.surface['container-low'],
              borderRadius: '50px',
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              px: 0.75,
              py: 0.25,
              textTransform: 'capitalize',
            },
          },
          {
            props: { variant: 'multiselected' },
            style: {
              ':focus': {
                backgroundColor: palette.surface['container-high'],
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              },
              ':hover': {
                backgroundColor: palette.surface.container,
              },
              backgroundColor: palette.buttons.tonal.dark['on-surface'],
              borderRadius: '50px',
              fontFamily: 'aktiv-grotesk, sans-serif',
              fontSize: 14,
              fontWeight: 500,
              px: 0.75,
              py: 0.25,
              textTransform: 'capitalize',
            },
          },
        ],
      },
    },
    palette: {
      school: {
        accent: school.accent_color,
        action: school.action_color,
        action2: school.action_2_color,
        action2Reverse: school.action_color_2_reverse,
        actionReverse: school.action_reverse_color,
        buttonFont: school.button_font_color,
        buttonGradient: `linear-gradient(-180deg, ${school.gradient_start_color} 0%, ${school.gradient_stop_color} 99%)`,
        flag: school.flag_color,
        gradientStart: school.gradient_start_color,
        gradientStop: school.gradient_stop_color,
        highlight: school.highlight_color,
        infoLink: school.infoLink,
        primary: school.primary_color,
        secondary: school.secondary_color,
        tertiary: school.tertiary_color,
      },
      schoolMultiColor: {
        contrastText: '#fff',
        main: '#E0EAEC',
      },
    },
  }

  const combined = createTheme(deepmerge(schoolTheme, theme))

  return (
    <ThemeProvider theme={combined}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

MuiThemeProvider.propTypes = {
  children: PropTypes.node,
  school: PropTypes.shape({
    accent_color: PropTypes.string,
    action_2_color: PropTypes.string,
    action_color: PropTypes.string,
    action_color_2_reverse: PropTypes.string,
    action_reverse_color: PropTypes.string,
    button_font_color: PropTypes.string,
    flag_color: PropTypes.string,
    gradient_start_color: PropTypes.string,
    gradient_stop_color: PropTypes.string,
    highlight_color: PropTypes.string,
    infoLink: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    tertiary_color: PropTypes.string,
  }),
}

export default MuiThemeProvider
