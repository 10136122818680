import sendAnalytics from '../../../utils/sendAnalytics'
import WhyAmIBeingAskedThis from '../WhyAmIBeingAskedThis'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid2 from '@mui/material/Grid2'
import PropTypes from 'prop-types'
import { getRoleQuestionResponse } from '../../../utils/getRoleQuestionResponse'
import { getFormattedResourcesPath } from '../../../utils/getFormattedResourcesPath'
import { always, append, equals, ifElse, join, split, dropLast } from 'ramda'
import { getMyselfOtherQuestionResponse } from '../../../utils/getMyselfOtherQuestionResponse'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

const SingleSelectQuestion = ({
  analytics,
  goToResources,
  questionObject,
  questionDescription,
  isAccessCriteriaQuestion,
  isFirstQuestion,
  nextQuestionId,
}) => {
  const [searchParams] = useSearchParams()

  const location = useLocation()

  const navigate = useNavigate()

  const criteria = searchParams.get('criteria')
    ? searchParams.get('criteria').split(',')
    : []

  return (
    <Grid2 container spacing={1}>
      <Grid2 item size={{ xs: 10 }} sx={{ pl: 1.5 }}>
        <Box>
          <Stack spacing={2}>
            <Typography component={'h2'} sx={{ pb: 1 }} variant="h2">
              {location.pathname.includes('/them') && questionObject.them_name
                ? questionObject.them_name
                : questionObject.name}
            </Typography>
            {questionDescription && (
              <Typography variant="body3">{questionDescription}</Typography>
            )}
            <WhyAmIBeingAskedThis questionObject={questionObject} />
            <Box>
              <Stack>
                {questionObject.access_criteria.map((item, index) => {
                  // if is access criteria question, we add as query params instead of as a route
                  const getNavToUrl = ifElse(
                    equals(true),
                    // For access criteria question, return full object with criteria
                    always({
                      pathname: goToResources
                        ? getFormattedResourcesPath(location.pathname)
                        : join(
                            '/',
                            // if we are on the first question, we don't need to drop the previous question id from
                            // the url. otherwise we do
                            append(
                              nextQuestionId,
                              dropLast(
                                isFirstQuestion ? 0 : 1,
                                split('/', location.pathname)
                              )
                            )
                          ),
                      search: createSearchParams({
                        criteria: join(
                          ',',
                          searchParams.get('criteria')
                            ? append(
                                item.id,
                                split(',', searchParams.get('criteria'))
                              )
                            : [item.id]
                        ),
                      }).toString(),
                    }),
                    // For static question only return url string with item id
                    always(location.pathname + '/' + item.id)
                  )
                  return (
                    <Button
                      key={index}
                      onClick={() => {
                        navigate(getNavToUrl(isAccessCriteriaQuestion))
                        sendAnalytics(
                          location.pathname,
                          'single-select',
                          {
                            ...analytics,
                            ...item,
                            myself_other_answer: getMyselfOtherQuestionResponse(
                              location.pathname
                            ),
                            role_answer: getRoleQuestionResponse(
                              item.id,
                              criteria
                            ),
                          },
                          'single-select-page'
                        )
                      }}
                      sx={{ ml: 'auto', my: 2, px: 2, py: 1 }}
                      variant="single-select"
                    >
                      <Typography sx={{ fontSize: '16px' }} variant="body1">
                        {item.name}
                      </Typography>
                    </Button>
                  )
                })}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Grid2>
    </Grid2>
  )
}
SingleSelectQuestion.propTypes = {
  analytics: PropTypes.object,
  goToResources: PropTypes.bool,
  isAccessCriteriaQuestion: PropTypes.bool,
  isFirstQuestion: PropTypes.bool,
  nextQuestionId: PropTypes.number,
  questionDescription: PropTypes.string,
  questionObject: PropTypes.object,
}

export default SingleSelectQuestion
