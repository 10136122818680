import palette from '../palette'

export const MuiInputBase = {
  styleOverrides: {
    colorPrimary: {
      backgroundColor: palette.surface.container
    },
    root: {
      backgroundColor: palette.surface.container,
      fontSize: 16
    }
  }
}
