/* eslint-disable quotes */
import './app.css'
import MainWrapper from './components/MainWrapper'
import { setOrgDomain } from './utils/sessionStorage/orgDomain'
import MuiThemeProvider from './theme/MUIThemeProvider'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { getData } from './utils/api/getData'
import { setSchoolConfig } from './utils/sessionStorage/schoolConfig'
import { useState, useEffect } from 'react'
import { InitDataContextProvider } from './contexts/initDataContext'
import { QuestionContextProvider } from './contexts/questionContext'
import { setKeenSessionId } from './utils/sessionStorage/keenio'
import MixpanelInitializer from './components/mixpanel/MixpanelInitializer'
import { store } from './store/store'
import {
  always,
  and,
  concat,
  cond,
  drop,
  equals,
  includes,
  isEmpty,
  join,
  prop,
  split,
  T,
} from 'ramda'
import HeadComponent from './Head'
import AppRoutes from './routes/AppRoutes'
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter, useLocation } from "react-router-dom"
import HistoryHandler from "./handlers/HistoryHandler"
import AppFlowHandler from "./handlers/AppFlowHandler"

const App = () => {
  const [school, setSchool] = useState({})

  const isDev = equals(process.env.REACT_APP_PUBLIC_ENV, 'development')
  
  const urlReferer = window.location.host

  const getYouDomain = cond([
    [
      includes('findhelpnow'),
      always(concat('www.', join('.', drop(1, split('.', urlReferer))))),
    ],
    [includes('demo'), always(join('.', drop(1, split('.', urlReferer))))],
    [T, always(concat('you.', join('.', drop(1, split('.', urlReferer)))))],
  ])
  
  const renderOrgDomain = isDev
    ? process.env.REACT_APP_PUBLIC_DEFAULT_SCHOOL_DOMAIN
    : getYouDomain(urlReferer)
  
  useEffect(() => {
    getData(`/school-data?org_domain=${renderOrgDomain}`).then(res => {
      setSchool(res)
      setSchoolConfig(res)
      // set up session storage vars for keen-io service (since it doesn't have reliable access to contexts)
      setKeenSessionId()
      setOrgDomain(prop('domain', res))
    })
  }, [renderOrgDomain])

  if (and(typeof window === 'undefined', isEmpty(school))) return null

  return (
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
        <MixpanelInitializer />
          <MuiThemeProvider school={school}>
            <InitDataContextProvider domain={prop('domain', school)}>
              <QuestionContextProvider>
                <HistoryHandler />
                <AppFlowHandler domain={prop('domain', school)}/>
                <HeadComponent school={school}/>
                <MainWrapper school={school}>
                  <AppRoutes school={school} />
                </MainWrapper>
              </QuestionContextProvider>
            </InitDataContextProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  )
}

export default App
