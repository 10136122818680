/* eslint-disable sort-keys */
import { Skeleton } from '@mui/material'
import Stack from '@mui/material/Stack'

const ResourceSkeletonLoader = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        mx: 'auto',
        pb: 10,
        width: { xs: '90%', lg: '40%' },
      }}
    >
      <Skeleton animation="wave" height={55} variant="rectangular" />
      <Skeleton animation="wave" height={90} width="40%" />
      <Skeleton animation="wave" height={45} width="40%" />
      <Skeleton animation="wave" height={210} variant="rectangular" />
      <Skeleton animation="wave" height={45} width="30%" />
      <Skeleton animation="wave" height={45} width="40%" />
      <Skeleton animation="wave" height={45} width="40%" />
      <Skeleton animation="wave" height={45} width="30%" />
      <Skeleton animation="wave" height={45} width="40%" />
    </Stack>
  )
}

export default ResourceSkeletonLoader
