/* eslint-disable sort-keys */
import { useState, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import sendAnalytics from '../../utils/sendAnalytics'
import SortMenu, { SORT_OPTION_MOST_RELEVANT } from './SortMenu'
import YouResourceCard from '../ResourceCards/YouResourceCard'
import { useInitDataContext } from '../../contexts/initDataContext'
import SortedStandardResources from './SortedStandardResources'
import Box from '@mui/material/Box'
import { Button, Container, Stack, Typography } from '@mui/material'
import { Masonry } from '@mui/lab'
import { ResourcesContextProvider } from '../../contexts/resourcesContext'
import ResourceHeader from './ResourceHeader'
import useMediaQuery from '@mui/material/useMediaQuery'
import ResourcesSkeletonLoader from './ResourcesSkeletonLoader'
import { useGetResourcesQuery } from '../../api/triageApi'
// import { ArrowBackIos } from '@mui/icons-material'
import { getOrgDomain } from '../../utils/sessionStorage/orgDomain'
import {
  concat,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  map,
  not,
  or,
  prop,
  propOr,
  reduce,
  sortBy,
} from 'ramda'
import { QuestionContext } from '../../contexts/questionContext'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Icon from 'components/Icon/Icon'
import CopyLinkButton from 'components/CopyLinkButton'

// const enterAnimation = {
//   animation: { opacity: 1 },
//   duration: 500,
//   easing: 'easeOutCubic',
//   style: { opacity: 0 },
// }

function Resources({ school }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { categories, loading, questions } = useInitDataContext()
  const { previousNeeds, resetContext, seeAllTopics } =
    useContext(QuestionContext)
  const [selectedSortOption, setSelectedSortOption] = useState(
    SORT_OPTION_MOST_RELEVANT
  )
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // info gathered from router
  const criteria = searchParams.get('criteria') || ''
  const criteriaArray = searchParams.get('criteria') ? criteria.split(',') : []
  const roleQuestionParam = criteriaArray.length
    ? parseInt(criteriaArray[0])
    : null
  const needs = searchParams.get('needs') || ''
  const multiselect = searchParams.get('multiselect') || ''
  const isEmergency = location.pathname.includes('/emergency/')
  const isHelper = location.pathname.includes('them')
  const resourcesAnalytics = {
    allTopics: seeAllTopics,
    criteria,
    isEmergency,
    isHelper,
    needs: previousNeeds,
    path: location.pathname,
    role_answer: roleQuestionParam == 1 ? 'Student' : 'Faculty',
  }

  useEffect(() => {
    localStorage.setItem('previousPath', location.pathname)
  }, [])

  const allNeeds = useMemo(() => {
    if (isNil(categories)) return []
    return reduce(
      (needs, category) =>
        concat(needs, map(prop(['id']), prop('needs', category))),
      [],
      categories
    )
  }, [categories])

  const { data: response, isFetching } = useGetResourcesQuery(
    new URLSearchParams({
      criteria: gt(length(criteria), 0) ? criteria : '',
      emergency: isEmergency ? 1 : 0,
      multiselect: gt(length(multiselect), 0) ? multiselect : '',
      // we hit a validation error if needs are empty. so if needs.length is 0 or needs are "none", we need to get all needs
      needs: or(equals('none', needs), equals('', needs)) ? allNeeds : needs,
      org_domain: getOrgDomain(),
    }),
    {
      // rtk query does not refresh with the emergency parameter change, so we have to force refetch
      refetchOnMountOrArgChange: true,
      skip: isEmpty(allNeeds),
    }
  )

  return (
    <ResourcesContextProvider
      categories={categories}
      criteria={criteria}
      isEmergency={isEmergency}
      isHelper={isHelper}
      needs={needs}
      questions={questions}
    >
      <Box
        sx={{
          backgroundColor: 'surface.surface',
        }}
      >
        <Container
          sx={{
            minHeight: '100vh',
          }}
        >
          <Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ mb: -1, mt: '64px' }}
            >
              {school.link_to_you ? (
                <Button
                  href={'https://' + school.domain}
                  LinkComponent="a"
                  onClick={() =>
                    sendAnalytics(
                      location.pathname,
                      'you-link',
                      resourcesAnalytics,
                      'resources-page'
                    )
                  }
                  rel="noreferrer"
                  startIcon={
                    <Box
                      alt="You Icon"
                      component="img"
                      display="inline flex"
                      height="25px"
                      src="/images/triage/you-triad.svg"
                    />
                  }
                  sx={{
                    '&:focus': {
                      backgroundColor: 'buttons.tonal.background-focused',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    },
                    '&:hover': {
                      backgroundColor: 'buttons.tonal.background-hover',
                    },
                    backgroundColor: 'buttons.tonal.background-default',
                    borderRadius: '20px',
                    color: 'buttons.tonal.on-surface',
                    fontFamily: 'mark-ot-bold',
                    textDecoration: 'none',
                    textTransform: 'none',
                    width: 'fit-content',
                  }}
                  target="_blank"
                  variant="buttonPrimary"
                >
                  <Typography variant="subtitle1">
                    Visit YOU at {school.letters}
                  </Typography>
                </Button>
              ) : (
                <></>
              )}
              {not(isMobile) && (
                <CopyLinkButton
                  resourceAnalytics={{
                    pageType: 'resources-page',
                  }}
                />
              )}
            </Stack>
            <ResourceHeader
              pageType={'resources-page'}
              school={school}
              sx={{ mb: 2 }}
            />

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              mt={{ xs: 3 }}
              px={1}
              spacing={1}
              sx={{ justifyContent: 'space-between' }}
            >
              <Button
                onClick={() => {
                  sendAnalytics(
                    location.pathname,
                    'start-new-search',
                    resourcesAnalytics,
                    'resources-page'
                  )
                  resetContext()
                  navigate('/triage')
                }}
                sx={{
                  color: 'buttons.primary.background-default',
                  px: 0.75,
                  py: 0.25,
                }}
                variant="back-button"
              >
                <Icon sx={{ mr: 1 }}>arrow_back</Icon> Start a new search
              </Button>
              <SortMenu
                hasOnCampus={response?.has_on_campus}
                onChange={setSelectedSortOption}
              />
            </Stack>

            {isFetching || loading ? (
              <ResourcesSkeletonLoader />
            ) : (
              <Stack mt={2} spacing={2}>
                <SortedStandardResources
                  selectedSortOption={selectedSortOption}
                  standard_resources={propOr(
                    [],
                    'standard',
                    response?.resources
                  )}
                />

                <Masonry columns={isMobile ? 1 : 3} spacing={2}>
                  {sortBy(
                    prop('title'),
                    propOr([], 'you', response?.resources)
                  ).map((resource, i) => (
                    <YouResourceCard
                      key={i}
                      resource={resource}
                      school={school}
                    />
                  ))}
                </Masonry>
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
    </ResourcesContextProvider>
  )
}

Resources.propTypes = {
  school: PropTypes.object,
}

export default Resources
