import YouLogoBadge from '../YouLogoBadge'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import sendAnalytics from '../../utils/sendAnalytics'
import { ResourcesContext } from '../../contexts/resourcesContext'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const YouResourceCard = ({ resource, school }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { baseResourceAnalytics } = useContext(ResourcesContext)

  return (
    <Card
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 0px #5F5F5F26',
        shadow: '2dp',
      }}
    >
      <CardActionArea
        onClick={() => {
          sendAnalytics(
            location.pathname,
            'resource-card',
            {
              resource,
              ...baseResourceAnalytics,
            },
            'resource'
          )
          navigate('/resource/' + resource.id)
        }}
      >
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Box
              alt={resource.title + ' Image'}
              sx={{
                backgroundImage: `url('${resource.image_url}')`,
                backgroundSize: 'cover',
                height: '150px',
                p: 0,
                width: '100%',
              }}
            />
          }
        />
        <CardContent>
          <Box>
            <Typography component="h4" variant="h5">
              {resource.title}
            </Typography>
            <Typography variant="caption">{parse(resource.summary)}</Typography>
          </Box>
        </CardContent>
        <CardContent sx={{ p: 0 }}>
          <YouLogoBadge schoolLogo={school.logoImage} />
          {/*mui card content adds a mandatory padding of 24px*/}
          {/*so i stuck this box down here to have it apply to an*/}
          {/*empty div. theres gotta be a better way*/}
          <Box></Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

YouResourceCard.propTypes = {
  resource: PropTypes.object,
  school: PropTypes.object,
}

export default YouResourceCard
