import Question from '../../components/Question/Question'
import { QUESTION_TYPE_SINGLESELECT } from '../../utils/questionTypes'

const TriagePage = school => (
  <Question
    isFirst={true}
    progressStep={0}
    questionObject={{
      access_criteria: [
        { id: 'emergency', name: 'Emergency' },
        { id: 'non-emergency', name: 'Not an emergency' },
      ],
      id: null,
      name: 'Are you seeking help for an emergency or non-emergency situation?',
      why_asking:
        'We want to direct you to the right resources, if this is an emergency we will prioritize resources that are more often available 24/7 and prepared to respond to a crisis.',
    }}
    school={school}
    triage_logo={school.flagImage}
    type={QUESTION_TYPE_SINGLESELECT}
  />
)

export default TriagePage
