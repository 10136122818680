/* eslint-disable sort-keys */
import { createTheme } from '@mui/material/styles'
import components from './components'
import palette from './palette'
import { typography } from './typography'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1000,
      xl: 1536,
    },
  },
  components,
  palette,
  spacing: 8,
  typography,
})

export default theme
