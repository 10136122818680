import ToolTip from '../FHNToolTip'
import PropTypes from 'prop-types'
import { and, includes, not, or } from 'ramda'

const WhyAmIBeingAskedThis = ({ questionObject }) => {
  const optionalTooltips = ['role', 'emergency', 'standard']
  const ifToolTip = or(
    not(questionObject.type),
    and(
      includes(questionObject.type, optionalTooltips),
      questionObject.why_asking
    )
  )

  return (
    ifToolTip && (
      <ToolTip
        message={questionObject.why_asking}
        tooltipLabel="Why am I being asked this?"
      />
    )
  )
}

WhyAmIBeingAskedThis.propTypes = {
  questionObject: PropTypes.object,
}

export default WhyAmIBeingAskedThis
