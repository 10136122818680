const ORG_DOMAIN_KEY = 'org_domain'

/**
 * @param allowed
 *
 * Explanation of the business logic below and where it is used.
 *
 * org domain is how we know which school we are on without a logged in user
 *
 * Explanation of the technical decisions
 *
 * Before, this info was housed in a reducer (accessible by the services), but with the
 * advent of Next.js we had to move to context only due to reducers resetting on route change
 * and my not finding an elegant way to pass the entire state props through which used to be
 * handled by React Router but isn't by Next router (NOTE THAT THIS STATEMENT IS A THEORY NOT A FACT).
 * The contexts are not loadable in the services, so we have to use the session storage to hold this
 * so that we don't have to pass the user through every single ga tracking call.
 *
 * The variable is set when the user is fetched in the _app set up.
 *
 * Stan 7/26/2022
 */

export const setOrgDomain = (allowed) => {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    sessionStorage.setItem(ORG_DOMAIN_KEY, allowed)
  }
}

export const getOrgDomain = () => {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    const unsanitizedOrgDomain = sessionStorage.getItem(ORG_DOMAIN_KEY)
    // Sanitize the org domain from storage to prevent SQL injection
    const sanitizedOrgDomain = unsanitizedOrgDomain?.replace(/'/g, '')
    return sanitizedOrgDomain
  }
  return
}

export const clearOrgDomain = () => {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    sessionStorage.removeItem(ORG_DOMAIN_KEY)
  }
}
