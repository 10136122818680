import palette from 'theme/palette'

export const MuiMenuItem = () => ({
  styleOverrides: {
    root: {
      backgroundColor: 'red',
      padding: '8px 24px 8px 16px',
      width: '180px'
    }
  }
})
