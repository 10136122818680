import { MuiInputBase } from './MuiInputBase'
import MuiMasonry from './MuiMasonry'
import { MuiMenu } from './MuiMenu'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiFilledInput} from './MuiFilledInput'
import { MuiSelect } from './MuiSelect'
import { MuiAccordionDetails } from './MuiAccordionDetails'
import { MuiAccordionSummary } from './MuiAccordionSummary'

const components = {
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiFilledInput,
  MuiInputBase,
  MuiMasonry,
  MuiMenu,
  MuiMenuItem,
  MuiSelect,
}

export default components
