import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import PropTypes from 'prop-types'
import { useRef, useEffect } from 'react'

const ErrorBadge = ({ message }) => {
  const box_reference = useRef(null)

  useEffect(() => {
    box_reference.current.focus()
  }, [])

  return (
    <Box
      ref={box_reference}
      sx={{
        alignItems: 'center',
        border: '2px solid #db4c44',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '15px',
        fontWeight: 'bold',
        maxWidth: 400,
        mt: 1,
        p: 0.5,
      }}
      tabIndex={0}
    >
      <Icon color="error" sx={{ mr: 1 }}>
        error
      </Icon>
      <Typography color="error" variant="body3">
        {message}
      </Typography>
    </Box>
  )
}

ErrorBadge.propTypes = {
  message: PropTypes.string,
}

export default ErrorBadge
