import { useContext, useEffect, useState } from 'react'
import StandardResourceCard from '../ResourceCards/StandardResourceCard'
import {
  SORT_OPTION_ALPHABETIC,
  SORT_OPTION_ON_OFF_CAMPUS,
  SORT_OPTION_PRICE,
  SORT_OPTION_MOST_RELEVANT,
} from './SortMenu'
import { Masonry } from '@mui/lab'
import HelperResourceCard from '../ResourceCards/HelperResourceCard'
import PropTypes from 'prop-types'
import { ResourcesContext } from '../../contexts/resourcesContext'
import {
  equals,
  gt,
  insert,
  length,
  min,
  prop,
  reverse,
  sort,
  sortBy,
} from 'ramda'
import { useSearchParams } from 'react-router-dom'

const SortedStandardResources = ({
  standard_resources,
  selectedSortOption,
}) => {
  const [searchParams] = useSearchParams()
  const { isEmergency, isHelper, baseResourceAnalytics } =
    useContext(ResourcesContext)
  // sort logic for standard resources
  const [standardResourcesSorted, setStandardResourcesSorted] =
    useState(standard_resources)
  useEffect(() => {
    switch (selectedSortOption) {
      case SORT_OPTION_ALPHABETIC:
        setStandardResourcesSorted(sortBy(prop('title'), standard_resources))
        break
      case SORT_OPTION_PRICE:
        setStandardResourcesSorted(
          reverse(sortBy(prop('cost'), standard_resources))
        )
        break
      case SORT_OPTION_ON_OFF_CAMPUS:
        setStandardResourcesSorted(
          sort((a, b) => {
            let direction = 1
            if (
              a.location_text.includes('on-campus') &&
              !b.location_text.includes('on-campus')
            )
              direction = -1
            return direction
          }, standard_resources)
        )
        break
      case SORT_OPTION_MOST_RELEVANT:
        setStandardResourcesSorted(
          sort((a, b) => {
            let direction = 1
            const a_feature_multiplier = a.is_featured ? 1000 : 1
            const b_feature_multiplier = b.is_featured ? 1000 : 1
            let a_needs = prop('needs', a)
            let b_needs = prop('needs', b)
            if (
              searchParams.get('needs') &&
              searchParams.get('needs') !== 'none'
            ) {
              const selected_needs = searchParams.get('needs').split(',')
              a_needs = a_needs.filter(need => selected_needs.includes(need.id))
              b_needs = b_needs.filter(need => selected_needs.includes(need.id))
            }
            if (
              a_feature_multiplier * getHighestNeedRank(a_needs) >
              b_feature_multiplier * getHighestNeedRank(b_needs)
            )
              direction = -1
            return direction
          }, sortBy(prop('title'), standard_resources))
        )
        break
    }
  }, [selectedSortOption])

  const getHighestNeedRank = needs => {
    // We need to get the max rank value from the needs
    // To do so we get the ranks from needs, sort descending, then grab first
    // In order to have featured resources still register, no needs should count
    // As a rank of 1
    return gt(1, length(needs))
      ? 1
      : prop('rank', prop(0, reverse(sortBy(prop('rank'), needs))))
  }

  return (
    <Masonry columns={{ xs: 1, lg: 3 }} spacing={2}>
      {isHelper
        ? insert(
            min(2, length(standardResourcesSorted)),
            'helper',
            standardResourcesSorted
          ).map((resource, i) =>
            equals('helper', resource) ? (
              <HelperResourceCard
                key={resource.id}
                resourceAnalytics={baseResourceAnalytics}
              />
            ) : (
              <StandardResourceCard
                isEmergency={isEmergency}
                key={resource.id}
                resource={resource}
                resourceAnalytics={{
                  ...baseResourceAnalytics,
                  isHelper,
                  resource,
                }}
              />
            )
          )
        : standardResourcesSorted.map((resource, i) => (
            <StandardResourceCard
              isEmergency={isEmergency}
              key={resource.id}
              resource={resource}
              resourceAnalytics={{
                ...baseResourceAnalytics,
                isHelper,
                resource,
              }}
            />
          ))}
    </Masonry>
  )
}

SortedStandardResources.propTypes = {
  selectedSortOption: PropTypes.string,
  standard_resources: PropTypes.array,
}

export default SortedStandardResources
