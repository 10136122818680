import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const SchoolLogoImage = ({ logo, sx = {} }) => (
  <Box
    sx={{
      backgroundImage: 'url(' + logo + ')',
      backgroundSize: 'cover',
      height: '32px',
      width: '32px',
      ...sx,
    }}
  />
)

SchoolLogoImage.propTypes = {
  logo: PropTypes.string,
  sx: PropTypes.object,
}

export default SchoolLogoImage
