import LocationBadge from '../LocationBadge'
import ToolTip from '../FHNToolTip'
import ResourceField from './ResourceField'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { always, and, cond, equals, map, not, prop, split, trim } from 'ramda'
import { getPrivacyIcon } from '../../utils/getPrivacyIcon'
import { Stack, Typography, Grid, Box, Button } from '@mui/material'
import Icon from 'components/Icon/Icon'

const StandardResource = ({ resource, lookups, resourceAnalytics }) => {
  const [ctaList, setCtaList] = useState(null)

  // this coincides with the CTA list on the BE. Below are all the supported types
  // of CTA icons. If we add more, we will need to update the message on the
  // CMS helper text for CTA on TriageResources
  const getIcon = cond([
    [equals('map-pin'), always(<Icon sx={{ fontSize: 17 }}>location_on</Icon>)],
    [equals('link'), always(<Icon sx={{ fontSize: 17 }}>link</Icon>)],
    [equals('phone'), always(<Icon sx={{ fontSize: 17 }}>phone</Icon>)],
    [
      equals('calendar'),
      always(<Icon sx={{ fontSize: 17 }}>calendar_month</Icon>),
    ],
    [equals('email'), always(<Icon sx={{ fontSize: 17 }}>email</Icon>)],
    [equals('clock'), always(<Icon sx={{ fontSize: 17 }}>access_time</Icon>)],
    [
      equals('walk-in'),
      always(<Icon sx={{ fontSize: 17 }}>directions_walk</Icon>),
    ],
  ])

  useEffect(() => {
    if (and(not(equals('you', resource.type)), prop('cta_list', resource))) {
      setCtaList(
        map(
          resource => {
            const item = split(',', resource)
            return {
              label: item[0],
              link: item[1],
              // eslint-disable-next-line sort-keys
              class: item[2],
            }
          },
          split('\n', prop('cta_list', resource))
        )
      )
    }
  }, [])

  return (
    <Stack
      spacing={0}
      sx={{
        alignItems: { xs: 'left' },
        color: 'surface.on-surface',
        pb: 15,
        pt: 1,
      }}
    >
      <Typography component={'h1'} sx={{ mb: 3 }} variant="h1">
        {resource.title}
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mb: 1.25 }}>
        {resource.location_text?.map((location, i) => (
          <LocationBadge key={i} location={lookups.location_text[location]} />
        ))}
        {resource.location_access_criterion?.name && (
          <Typography
            component="span"
            sx={{
              alignItems: 'center',
              backgroundColor: 'surface.container-high',
              borderRadius: '50px',
              display: 'flex',
              px: 0.75,
              py: 0.25,
            }}
            variant="subtitle1"
          >
            <Icon
              fill
              sx={{
                fontSize: 16,
                mr: 1,
              }}
            >
              location_on
            </Icon>
            {resource.location_access_criterion.name}
          </Typography>
        )}
        {resource.privacy_label && (
          <Stack
            alignItems={'center'}
            component={'span'}
            direction={'row'}
            spacing={1}
          >
            <Stack
              alignItems={'center'}
              direction={'row'}
              spacing={1}
              sx={{
                backgroundColor: 'surface.container-high',
                borderRadius: '50px',
                display: 'flex',
                px: 0.75,
                py: 0.25,
              }}
            >
              {getPrivacyIcon(resource.privacy_label)}
              <Typography variant={'subtitle1'}>
                {lookups.privacy_label[resource.privacy_label]}
              </Typography>
            </Stack>
            <ToolTip message="Understanding how your information may be shared is important, this label indicates to what extent this service may share your information." />
          </Stack>
        )}
      </Stack>
      <Typography component="p" variant="subtitle1">
        {resource.summary ? parse(resource.summary) : ''}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={6} xs={12}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Typography component="h2" variant="h2">
                Services Available
              </Typography>
              <Grid container spacing={1}>
                {resource.services.map((service, i) => (
                  <Grid item key={i} xs={6}>
                    <Typography
                      sx={{
                        alignItems: 'center',
                        backgroundColor: 'surface.container-high',
                        borderRadius: 50,
                        display: 'flex',
                        px: 0.75,
                        py: 0.25,
                        width: 'fit-content',
                      }}
                      variant="body2"
                    >
                      <Icon sx={{ fontSize: 17, mr: 1 }}>done</Icon>
                      {lookups.services[service]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Stack>
            <Box>
              <Stack spacing={2}>
                <Typography component="h2" variant="h2">
                  Cost & Insurance
                </Typography>
                <Stack spacing={2} sx={{ pl: 1 }}>
                  <Stack direction={'row'} spacing={1}>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      sx={{
                        backgroundColor: 'surface.container-high',
                        borderRadius: 50,
                        px: 0.75,
                        py: 0.25,
                      }}
                    >
                      <Icon sx={{ fontSize: 17 }}>attach_money</Icon>
                      <Typography>{lookups.cost[resource.cost]}</Typography>
                    </Stack>
                    <ToolTip message="Before accessing a service, it can be helpful to know if costs are involved. This label indicates whether this service has an expense to you." />
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      sx={{
                        backgroundColor: 'surface.container-high',
                        borderRadius: 50,
                        px: 0.75,
                        py: 0.25,
                      }}
                    >
                      <Icon sx={{ fontSize: 17 }}>beach_access</Icon>
                      <Typography>
                        {'Insurance ' +
                          lookups.insurance[resource.insurance].toLowerCase()}
                      </Typography>
                    </Stack>
                    <ToolTip message="Some services may accept or require health insurance, this label indicates whether insurance is applicable to this service." />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack
            spacing={3}
            sx={{
              backgroundColor: 'surface.container-low',
              borderRadius: '1rem',
              height: '100%',
              ml: { xs: -1.5 },
              p: 3,
              width: '100%',
            }}
          >
            <Typography component="h2" variant="h2">
              Get Connected
            </Typography>
            {resource.link && (
              <Button
                href={resource.link}
                resourceAnalytics={resourceAnalytics}
                target="_blank"
                variant="outlined"
              >
                <Icon sx={{ fontSize: 14, mr: 1 }}>captive_portal</Icon>
                <Typography variant="subtitle1">Visit Website</Typography>
              </Button>
            )}
            {/*Images in the public folder need to matched up to the classes we have set in the backend for all dynamic resource fields*/}
            {ctaList?.map((cta, ind) => (
              <ResourceField
                icon={getIcon(trim(prop('class', cta)))}
                key={ind}
                link={cta.link}
                resourceAnalytics={resourceAnalytics}
                text={cta.label}
              />
            ))}
            {resource.location_line_1 && resource.location_map_url && (
              <ResourceField
                icon={
                  <Icon
                    sx={{
                      background: 'none',
                    }}
                  >
                    location_on
                  </Icon>
                }
                isMultiline={resource.location_line_2?.length > 0}
                link={resource.location_map_url}
                resourceAnalytics={resourceAnalytics}
                text={resource.location_line_1}
                text2={resource.location_line_2}
              />
            )}
            {resource.hours_text &&
              equals('view', resource.hours_text) &&
              resource.hours_link_url && (
                <ResourceField
                  icon={<Icon sx={{ fontSize: 17 }}>access_time</Icon>}
                  link={resource.hours_link_url}
                  resourceAnalytics={resourceAnalytics}
                  text={lookups.hours_text[resource.hours_text]}
                />
              )}
            {resource.hours_text &&
              not(equals('view', resource.hours_text)) && (
                <Typography
                  color="surface.on-surface"
                  component="span"
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'surface.container-high',
                    borderRadius: 50,
                    display: 'flex',
                    px: 0.75,
                    py: 0.25,
                    width: 'fit-content',
                  }}
                  textTransform={'capitalize'}
                  variant="body3"
                >
                  <Icon
                    sx={{
                      background: 'none',
                      fontSize: 14,
                      mr: 1,
                    }}
                  >
                    brightness_4
                  </Icon>
                  {resource.hours_text}
                </Typography>
              )}
            {resource.phone_number && (
              <ResourceField
                icon={
                  <Icon
                    sx={{
                      background: 'none',
                      fontSize: 14,
                    }}
                  >
                    phone
                  </Icon>
                }
                isPhoneNumber={true}
                link={resource.phone_number}
                resourceAnalytics={resourceAnalytics}
                text={resource.phone_number}
              />
            )}
            {resource.email_address && (
              <ResourceField
                icon={
                  <Icon
                    sx={{
                      background: 'none',
                      fontSize: 14,
                    }}
                  >
                    email
                  </Icon>
                }
                isEmail={true}
                link={resource.email_address}
                resourceAnalytics={resourceAnalytics}
                text={resource.email_address}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

StandardResource.propTypes = {
  lookups: PropTypes.object,
  resource: PropTypes.object,
  resourceAnalytics: PropTypes.object,
}

export default StandardResource
