/**
 * Constructs query string and gets data
 * @param {string} url
 * @returns
 */
export const getData = url =>
  fetch(process.env.REACT_APP_PUBLIC_YOU_API_HOST + url, {
    credentials: 'omit',
    headers: new Headers({
      Accept: 'application/json',
    }),
    method: 'GET',
    mode: 'cors',
  }).then(res => res.json())
