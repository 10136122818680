import { getSchoolConfig } from '../../../../../utils/sessionStorage/schoolConfig'
import Question from '../../../../../components/Question/Question'
import { useInitDataContext } from '../../../../../contexts/initDataContext'
import getQuestionType from '../../../../../utils/getQuestionType'
import { useState, useEffect } from 'react'
import { findIndex, gte, inc, length, lt, propEq } from 'ramda'
import { useParams } from 'react-router-dom'

const EmergencyQuestionDisplay = () => {
  const { question } = useParams()
  const school = getSchoolConfig()
  // you answer the "who needs help?" question in the [person] index route. So we can assume we are on the
  // second progress step if we make it to this component
  const progressStep = 2
  const { flow } = useInitDataContext()
  // emergency question flow
  const [currentFlow, setCurrentFlow] = useState(flow?.emergency)

  // set in useEffect as is not available on page load
  useEffect(() => {
    setCurrentFlow(flow?.emergency)
  }, [flow])

  // short circuit if questions are null
  if (currentFlow == null) return null

  // index of current question
  const questionIndex = findIndex(propEq(parseInt(question), 'id'), currentFlow)
  const nextIndex = inc(questionIndex)

  return (
    <>
      <Question
        goToResources={gte(nextIndex, length(currentFlow))}
        isAccessCriteriaQuestion={true}
        nextQuestionId={
          lt(nextIndex, length(currentFlow)) ? currentFlow[nextIndex].id : null
        }
        progressStep={progressStep}
        questionObject={currentFlow.find(q => q.id === parseInt(question))}
        school={school}
        triage_logo={school.flagImage}
        type={getQuestionType(currentFlow[questionIndex])}
      />
    </>
  )
}

export default EmergencyQuestionDisplay
