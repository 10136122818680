/* eslint-disable sort-keys */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { slugify } from '../utils/slugify'
import parse from 'html-react-parser'
import { equals, prop } from 'ramda'

const Quicklinks = ({ rawHtml, headingElement }) => {
  const [activeQuicklink, setActiveQuicklink] = useState(null)

  const htmlSplit = rawHtml.match(/<h(.)>.*?<\/h\1>/g)
  const sideAnchors = htmlSplit.map(tag => tag.slice(4, -5))

  const sections = rawHtml
    .split(`<${headingElement}>`)
    .map((content, index) => {
      const id = slugify(prop(index, sideAnchors))
      return {
        content: `<${headingElement} id="${id}">${content}`,
        link: `#${id}`,
      }
    })

  const wrapperStyle = {
    '& > h1': {
      fontSize: '50px',
      lineHeight: '43px',
    },
    '& > hr': {
      display: 'none',
    },
    '& > img': {
      border: '1px solid #000',
      marginBottom: '16px',
      width: '100%',
    },
    '& > ol': {
      '& > li': {
        marginBottom: '16px',
      },
      'listStyle': 'decimal',
      'marginLeft': '32px',
    },
    '& > p': {
      lineHeight: '32px',
      marginBottom: '8px',
    },
    '& > table': {
      '& > td': {
        border: '1px solid #000',
        borderCollapse: 'collapse',
        padding: '10px',
        textAlign: 'center',
      },
      '& > thead': {
        fontWeight: 'bold',
      },
      '& > thead, tbody': {
        width: '100%',
      },
      'border': '1px solid #000',
      'borderCollapse': 'collapse',
      'marginBottom': '48px',
      'width': '100%',
    },
    '& > ul': {
      '& > li': {
        '& > .li-header': {
          fontWeight: 'bold',
          marginBottom: '8px',
        },
        '& > ul': {
          listStyle: 'circle',
        },
        'marginBottom': '16px',
      },
      'listStyle': 'inherit',
    },
    'display': 'grid',
    'gridTemplateColumns': { xs: '1fr', md: '3fr 7fr' },
    'position': 'relative',
  }

  const quicklinksWrapStyle = {
    alignSelf: 'flex-start',
    display: { xs: 'none', md: 'block' },
    fontSize: 22,
    letterSpacing: '0.5px',
    lineHeight: '22px',
    pl: { xs: 1, md: 2 },
    position: 'sticky',
    py: 1.5,
    top: '60px',
  }

  const quicklinksStyle = active => ({
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
    fontFamily: active ? 'mark-ot-bold' : 'mark-ot',
    padding: '0.75rem',
    textDecoration: 'none',
  })

  return (
    <Box {...wrapperStyle}>
      <Box {...quicklinksWrapStyle}>
        {sideAnchors
          .map((link, index) => (
            <Typography
              component={'a'}
              href={'#' + slugify(link)}
              key={index}
              onClick={() => setActiveQuicklink('#' + slugify(link))}
              sx={{
                ...quicklinksStyle(
                  equals(activeQuicklink, `#${slugify(link)}`)
                ),
              }}
              variant="body1"
            >
              {link}
            </Typography>
          ))
          .slice(1)}
      </Box>
      <Box
        sx={{
          overflow: 'auto',
          pl: { xs: 2, md: 0 },
          pr: { xs: 2, md: 3 },
          py: { xs: 1.5, sm: 3, md: 4 },
        }}
      >
        {sections.map((section, index) => (
          <Box key={index}>{parse(section.content)}</Box>
        ))}
      </Box>
    </Box>
  )
}

Quicklinks.propTypes = {
  headingElement: PropTypes.string.isRequired,
  rawHtml: PropTypes.string.isRequired,
}

// Specifies the default values for props:
Quicklinks.defaultProps = {
  headingElement: 'h2',
}

export default Quicklinks
