import { T, always, cond, includes, split } from 'ramda'

export const getMyselfOtherQuestionResponse = (path) => {
    const path_arr = split('/', path)
    const getMyselfOtherFromPath = cond([
        [includes('them'), always('them')],
        [includes('me'), always('me')],
        [T, always(null)],
      ])
    return getMyselfOtherFromPath(path_arr)
}