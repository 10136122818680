/* eslint-disable sort-keys */
import CopyLinkButton from './CopyLinkButton'
import sendAnalytics from '../utils/sendAnalytics'
import PropTypes from 'prop-types'
import { not } from 'ramda'
import {
  Box,
  List,
  ListItem,
  Stack,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Icon from './Icon/Icon'

// const enterAnimation = {
//   animation: { opacity: 1 },
//   duration: 500,
//   easing: 'easeOutCubic',
//   style: { opacity: 0 },
// }

const helperImg = '/images/triage/helper-placeholder.svg'

function HelperDetail({ resourcesVisited }) {
  const navigate = useNavigate()
  const jumpToScenarioDiv = id => {
    document.getElementById(id).scrollIntoView()
  }
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ backgroundColor: 'surface.surface' }}>
      <Box
        id="helper-page"
        sx={{ m: 'auto', maxWidth: 684, pb: 8, position: 'relative' }}
      >
        <Box
          component="nav"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: { xs: 'space-around', lg: 'space-between' },
            pt: 3,
            width: '100%',
          }}
        >
          {resourcesVisited ? (
            <Button
              onClick={() => {
                sendAnalytics(
                  window.location.href,
                  'back-nav',
                  {},
                  'resource-helper'
                )
                navigate(-1)
              }}
              sx={{ color: 'link.main' }}
              variant="back-button"
            >
              <Icon sx={{ mr: 1 }}>arrow_back</Icon>
              Back To Results
            </Button>
          ) : (
            <Button onClick={() => navigate('/triage')} variant="back-button">
              <Icon sx={{ mr: 1 }}>arrow_back</Icon>
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant="subtitle1"
              >
                Start a New Search
              </Typography>
            </Button>
          )}
          <Box>
            {not(isMobile) && (
              <CopyLinkButton
                resourceAnalytics={{ pageType: 'resource-helper' }}
              />
            )}
          </Box>
        </Box>

        <Stack spacing={3} sx={{ m: 4 }}>
          <Box component="section">
            <Box
              alt="Helper Image"
              component="img"
              src={helperImg}
              sx={{ width: '100%' }}
            />
            <Typography sx={{ mt: 3 }} variant="h1">
              Not sure how to help?
            </Typography>
            <Typography sx={{ mt: 5 }} variant="subtitle1">
              One of the most caring things you can do for a friend is listen.
              You don’t have to “fix” the problem or have all the answers.
              Asking directly about how someone is feeling can be a good door
              opener. Most importantly, if you don&apos;t feel you can offer
              support, find someone who can.
            </Typography>
          </Box>
          <Typography sx={{ mb: 2 }} variant="subtitle1">
            Jump to Scenario
          </Typography>
          <List disablePadding>
            <ListItem disablePadding>
              <Button
                href="#offer-support"
                sx={{
                  color: 'link.main',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                }}
                variant="text"
              >
                <Typography variant="subtitle1">
                  When to Offer Support
                </Typography>
                <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
              </Button>
            </ListItem>
            <ListItem disablePadding>
              <Button
                href="#difficult-convos"
                sx={{
                  color: 'link.main',
                  textAlign: 'left',
                  textDecoration: 'none',
                  textTransform: 'capitalize',
                }}
                variant="text"
              >
                <Typography variant="subtitle1">
                  Having Difficult Conversations{' '}
                </Typography>
                <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
              </Button>
            </ListItem>
            <ListItem disablePadding>
              <Button
                href="#immediate-crisis"
                sx={{
                  color: 'link.main',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                }}
                variant="text"
              >
                <Typography variant="subtitle1">
                  Helping Someone in Immediate Crisis{' '}
                </Typography>
                <Icon sx={{ ml: 1 }}>arrow_forward</Icon>
              </Button>
            </ListItem>
          </List>
          <Box
            component="section"
            id="offer-support"
            sx={{ scrollMarginTop: '130px' }}
          >
            <Typography sx={{ mb: 3 }} variant="h2">
              {' '}
              When to Offer Support
            </Typography>
            <Typography component="p" variant="subtitle1">
              Talking to acquaintances, friends, and/or family about personal
              challenges can be an opportunity to provide information, support,
              and guidance.
            </Typography>
            <Typography component="p" variant="subtitle1">
              Feelings of distress can take many forms - whether it is coping
              with a stressful event, a family challenge, financial hardship, or
              a mental/physical health condition. We can all play a role in
              helping someone navigate a path forward. The process starts with
              recognizing warning signs that may communicate an individual is
              struggling or experiencing emotional pain.
            </Typography>
            <Typography variant="h3">Signs to Look For</Typography>
            <Box sx={{ mb: 3, mt: 3 }}>
              <Typography component={'span'} variant="subtitle1">
                When a person is in distress, take note of “warning signs” or
                “red flags” that indicate something might be “off.” Signs may be
                expressed through words, actions, and/or feelings. For example:
              </Typography>
            </Box>
            <Typography component={'ul'}>
              <Typography component={'li'} variant="subtitle1">
                Not answering texts, phone calls, or emails
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Changes in appearance
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Consistent comments about being overwhelmed, lost, not knowing
                what to do, or feeling stuck
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Changes in substance use
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Increased frustration and/or anger
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Increased confusion and/or lethargy
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Withdrawn
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Feeling like a burden
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Hopelessness
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Expressing a plan to take their life
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Making jokes or innuendos about taking their life (“they won’t
                miss me”)
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                In possession of firearms or other lethal means (eg.,
                medications, sharp objects, etc.)
              </Typography>
            </Typography>
            <Typography sx={{ mb: 3, mt: 3 }} variant="h4">
              Key Takeaway
            </Typography>
            <Typography component="p" variant="subtitle1">
              Trust your gut - if someone you know is acting differently in
              several areas of their life, it&apos;s likely a sign something is
              off. The best thing you can do is try to connect to learn more, or
              find someone who can.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              <Typography variant="subtitle1">Back to Top</Typography>
              <Icon sx={{ ml: 1 }}>arrow_upward</Icon>
            </Button>
          </Box>
          <Box
            component="section"
            id="difficult-convos"
            sx={{ scrollMarginTop: '130px' }}
          >
            <Typography sx={{ mb: 3 }} variant="h2">
              Having Difficult Conversations
            </Typography>
            <Typography component="p" variant="subtitle1">
              Offering help can feel intimidating. The good news - showing
              support and connecting others to care can be simple. The most
              important thing to remember is to be curious and direct about your
              concerns and desire to help.
            </Typography>
            <Typography variant="h3">Tips</Typography>
            <ul>
              <Typography component={'li'} variant="subtitle1">
                Have the conversation in a private setting
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Be curious and empathetic
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Be direct in sharing your concern (consider sharing observations
                about the warning signs that caused you concern)
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Be clear that you want to help
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Do more listening than talking; resist the temptation to
                interrupt
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Don&apos;t express judgement
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Don&apos;t rush the conversation
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Plan a next step or check-in
              </Typography>
            </ul>
            <Typography variant="h3">Thought Starters</Typography>
            <ul>
              <Typography component={'li'} variant="subtitle1">
                &quot;I&apos;ve noticed you haven&apos;t seemed like yourself
                recently, is everything okay?&quot;
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                &quot;I know that ______ (i.e. you&apos;ve been missing class,
                finances have been tight, a lot has been going on with your
                family) lately, is there anything I can do to help?&quot;
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                &quot;I&apos;m sorry you are going through this and really glad
                you told me. Have you considered seeking additional help? We can
                look together right now.&quot;
              </Typography>
            </ul>
            <Typography sx={{ mb: 3, mt: 3 }} variant="h3">
              Key Takeaway
            </Typography>
            <Typography component="p" variant="subtitle1">
              If you are worried about someone, the most important thing to do
              is say something. Simply taking the time to listen can go a long
              way and open the door to someone connecting with additional
              support. If you are still worried after an initial conversation,
              be persistent and follow up to show your support.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              <Typography variant="subtitle1">Back to Top</Typography>
              <Icon sx={{ ml: 1 }}>arrow_upward</Icon>
            </Button>
          </Box>
          <Box
            component="section"
            id="immediate-crisis"
            sx={{ scrollMarginTop: '130px' }}
          >
            <Typography sx={{ mb: 3 }} variant="h2">
              Helping Someone in Immediate Crisis
            </Typography>
            <Typography component="p" variant="subtitle1">
              If you are concerned about an individual and/or their safety,
              trust your instincts. If they are talking about suicide or self
              harm, connect with an appropriate professional, contact the
              Suicide & Crisis Lifeline by calling or texting 988, or call 911
              if it is an immediate medical or life threatening emergency.
            </Typography>
            <Typography component="p" variant="subtitle1">
              Whatever you do, do not keep it a secret and be clear in wanting
              to help. Here’s a few examples to clearly convey this message:
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'} variant="subtitle1">
                This is bigger than you and me. I think we need more help than I
                can provide. I know who to reach out to.
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                I&apos;m not going to leave you alone. I want to be sure you
                stay safe. Let&apos;s call 988 together.
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                Who would you like to reach out to? (Therapist, coach, parent,
                mentor, etc.)
              </Typography>
            </Typography>
            <Typography component="p" sx={{ mt: 2 }} variant="subtitle1">
              While reaching out for additional support might feel overwhelming,
              it&apos;s important to remember that individuals who receive
              emergency care are almost always grateful for the support after
              the crisis subsides.
            </Typography>
            <Typography sx={{ mb: 3 }} variant="h3">
              How to take action
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'} variant="subtitle1">
                If someone is in an immediate crisis, it&apos;s important that
                you or someone else stay with the person (assuming there is no
                immediate danger to bystanders) while you get support
              </Typography>
              <Typography component={'li'} variant="subtitle1">
                If you are able, offer one or two choices for connecting the
                individual to additional support, while being insistent that
                they connect. For example: &quot;I am going to get you some
                help. Would you like me to walk with you to the health center,
                call a crisis line, or call 988?&quot;
              </Typography>
            </Typography>
            <Typography sx={{ mb: 3, mt: 3 }} variant="h3">
              Key Takeaway
            </Typography>
            <Typography paragraph variant="subtitle1">
              Being non-judgemental, empathetic, and intentional about your
              desire to connect a person with care is the most effective way to
              provide support. In many instances, individuals will not get help
              on their own. By taking action, you can shorten the crisis and the
              individual can get the support they need. And remember, if you
              don&apos;t feel you can provide the support that the individual
              needs, find someone who can.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              <Typography variant="subtitle1">Back to Top</Typography>
              <Icon sx={{ ml: 1 }}>arrow_upward</Icon>
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
HelperDetail.propTypes = {
  resourcesVisited: PropTypes.bool,
}

export default HelperDetail
