import React, { useEffect, useState } from 'react'

import sendAnalytics from '../../utils/sendAnalytics'
import ToolTip from '../FHNToolTip'
import ResourceField from '../Resource/ResourceField'
import { useInitDataContext } from '../../contexts/initDataContext'
import { getNeedsFromCategories } from '../../utils/getNeedsFromCategories'
import LocationBadge from '../LocationBadge'
import PropTypes from 'prop-types'
import Icon from 'components/Icon/Icon'
import {
  ListItem,
  CardContent,
  CardHeader,
  CardActionArea,
  List,
  Card,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import parse from 'html-react-parser'
import {
  equals,
  filter,
  includes,
  length,
  map,
  not,
  or,
  pluck,
  prop,
} from 'ramda'
import { getPrivacyIcon } from '../../utils/getPrivacyIcon'
import { useNavigate } from 'react-router-dom'

const StandardResourceCard = ({ resource, isEmergency, resourceAnalytics }) => {
  const [needIndex, setNeedIndex] = useState(6)
  const [resourceNeedsTitles, setResourceNeedsTitles] = useState([])
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { lookups, categories } = useInitDataContext()
  const navigate = useNavigate()
  const path = window.location.href
  useEffect(() => {
    if (categories) {
      const allNeeds = getNeedsFromCategories(categories)
      setResourceNeedsTitles(
        map(
          prop('name'),
          filter(
            need => includes(need.id, pluck('id', resource.needs)),
            allNeeds
          )
        )
      )
    }
  }, [categories])

  const handleClick = () => {
    sendAnalytics(path, 'resource-card', resourceAnalytics, 'resource')
    navigate('/resource/' + resource.id)
  }

  const handleExpandNeeds = () => {
    needIndex === 6 ? setNeedIndex(resourceNeedsTitles.length) : setNeedIndex(6)
  }

  return (
    <Card
      sx={{
        backgroundColor: 'surface.container',
        borderRadius: '16px',
        boxShadow: '0px 2px 4px 0px #5F5F5F26',
        shadow: '2dp',
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardHeader
          title={
            <Typography
              component="h4"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mr: 1,
              }}
              variant="h4"
            >
              {resource.title}
              <Icon sx={{ color: 'buttons.text.on-surface' }}>
                arrow_forward
              </Icon>
            </Typography>
          }
        />
      </CardActionArea>
      <CardContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            {!isEmergency && (
              <Typography variant="body2">
                {resource.resource_type_label}
              </Typography>
            )}
            <Stack spacing={1}>
              {length(resource.location_text) > 0 &&
                resource.location_text?.map((location, ind) => (
                  <LocationBadge
                    key={ind}
                    location={lookups.location_text[location]}
                  />
                ))}
              {resource.privacy_label && (
                <Stack direction="row">
                  <Stack
                    component={'span'}
                    direction="row"
                    spacing={1}
                    sx={{
                      backgroundColor: 'surface.container-high',
                      borderRadius: 50,
                      px: 0.75,
                      py: 0.25,
                      width: 'fit-content',
                    }}
                  >
                    {getPrivacyIcon(resource.privacy_label)}
                    <Typography variant="body2">
                      {lookups.privacy_label[resource.privacy_label]}
                    </Typography>
                  </Stack>
                  <ToolTip
                    iconAfter={true}
                    message="Understanding how your information may be shared is important, this label indicates to what extent this service may share your information."
                    sx={{ pl: 0 }}
                  />
                </Stack>
              )}
              {resource.hours_text &&
                equals('available 24/7', resource.hours_text) && (
                  <Typography
                    component="span"
                    sx={{
                      alignItems: 'center',
                      backgroundColor: 'surface.container-high',
                      borderRadius: 50,
                      display: 'flex',
                      px: 0.75,
                      py: 0.25,
                      width: 'fit-content',
                    }}
                    textTransform="capitalize"
                    variant="body2"
                  >
                    <Icon
                      sx={{
                        background: 'none',
                        fontSize: 14,
                        mr: 1,
                      }}
                    >
                      brightness_4
                    </Icon>
                    {resource.hours_text}
                  </Typography>
                )}
              {resource.location_access_criterion?.name && (
                <Typography
                  component="span"
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'surface.container-high',
                    borderRadius: 50,
                    display: 'flex',
                    px: 0.75,
                    py: 0.25,
                    width: 'fit-content',
                  }}
                  variant="body2"
                >
                  <Icon fill sx={{ fontSize: 14, mr: 1 }}>
                    location_on
                  </Icon>
                  {resource.location_access_criterion.name}
                </Typography>
              )}
            </Stack>
          </Stack>

          {isEmergency ? (
            <Stack spacing={1}>
              {resource.phone_number && (
                <ResourceField
                  icon={
                    <Icon fill sx={{ fontSize: 14 }}>
                      call
                    </Icon>
                  }
                  isPhoneNumber={true}
                  link={'tel:' + resource.phone_number}
                  resourceAnalytics={resourceAnalytics}
                  text={resource.phone_number}
                />
              )}

              {resource.location_line_1 && resource.location_map_url && (
                <ResourceField
                  icon={
                    <Icon fill sx={{ fontSize: 14 }}>
                      location_on
                    </Icon>
                  }
                  link={resource.location_map_url}
                  resourceAnalytics={resourceAnalytics}
                  text={resource.location_line_1}
                />
              )}
            </Stack>
          ) : (
            <Typography variant="body1">
              {parse(resource.summary ?? '')}
            </Typography>
          )}

          {resourceNeedsTitles.length > 0 && or(not(isMobile), isEmergency) && (
            <>
              <List
                dense
                disablePadding
                subheader={
                  <Typography
                    sx={{
                      letterSpacing: '1.5px',
                    }}
                    variant="body2"
                  >
                    HELPS WITH
                  </Typography>
                }
              >
                {resourceNeedsTitles.slice(0, needIndex).map((title, i) => (
                  <ListItem key={i} sx={{ m: 1, p: 0 }}>
                    <Typography
                      sx={{
                        background: theme =>
                          theme.palette.surface['container-high'],
                        borderRadius: '8px',
                        px: 1,
                        py: 0.5,
                      }}
                      variant="needTag"
                    >
                      {title}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {resourceNeedsTitles.length > 6 && or(not(isMobile), isEmergency) && (
            <Button
              aria-label={
                needIndex > 6 ? 'See Less Content' : 'See More Content'
              }
              onClick={handleExpandNeeds}
              variant="text"
            >
              <Icon>
                {needIndex > 6 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Icon>
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

StandardResourceCard.propTypes = {
  isEmergency: PropTypes.bool,
  resource: PropTypes.object,
  resourceAnalytics: PropTypes.object,
}

export default StandardResourceCard
