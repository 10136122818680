const palette = {
  buttons: {
    outline: {
      'background-default': '#C4CEDO',
      'background-hover': '#EAEBE4',
    },
    primary:{
        'background-default': '#2B6772',
        'background-focused': '#275B62',
        'background-hover': '#1E545B',
        'on-surface': '#FFFFFF',
    },
    text: {
      'background-hover': '#2B677208',
      'background-focused': '#2B677212',
      'on-surface': '#2B6772'
    },
    tonal: {
      dark: {
        'on-surface': '#BFE2E9'
      },
      'background-default': '#E0EAEC',
      'background-focused': '#C7D9DC',
      'background-hover': '#CFDFE1',
      'on-surface': '#081E23',
    }
  },
  card: {
    'background-hover': '#F4EDE1'
  },
  error: {
    dark: '#FF633A',
    light: '#fef1e7',
    main: '#c22f1e',
  },
  grey: {
    1: '#333333',
    2: '#737373',
    3: '#B1B1B1',
    4: '#F3F3F3',
    5: '#FBFBFB',
    6: '#5F5F5F',
    light: '#ececec',
    socialSSO: '#F2F2F2',
  },
  info: {
    light: '#f1fbfe',
    main: '#1f61a3',
  },
  link: {
    main: '#088ab8',
  },
  success: {
    light: '#f3fbef',
    main: '#61A904',
  },
  surface:{
    'container': '#FAF4EA',
    'container-high': '#F4EDE2',
    'container-low': '#FCF8F0',
    'on-surface': '#211F1C',
    surface: '#FFFCF7',
  },
  warning: {
    main: '#FBCE36',
  },
}

export default palette
