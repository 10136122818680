import {
  QUESTION_TYPE_MULTISELECT,
  QUESTION_TYPE_NEEDS,
  QUESTION_TYPE_SINGLESELECT
} from '../utils/questionTypes'

const getQuestionType = (question) => {
  if (question) {
    if (question.multi_select) return QUESTION_TYPE_MULTISELECT
    if (question.type === 'needs') return QUESTION_TYPE_NEEDS
  }
  return QUESTION_TYPE_SINGLESELECT
}

export default getQuestionType