import { useEffect } from 'react'
import { all, dec, equals, isNil, length, not, path, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import trackEvent from '../utils/trackEvent'

const AppFlowHandler = domain => {
  const { history } = useSelector(prop('history'))
  const { pathname } = useLocation()

  const priorPage = prop(dec(dec(length(history))), history)

  useEffect(() => {
    if (
      all(equals(true))([not(isNil(domain)), not(equals(priorPage, pathname))])
    ) {
      trackEvent('HC App Flow', {
        'Page Title': pathname,
        Path: pathname,
        'Prior Page Title': priorPage,
        'Prior Path': priorPage,
      })
    }
  }, [length(history), domain])

  return
}

export default AppFlowHandler
