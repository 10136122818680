const session_key = '@you:keenSessionId'

export function clearKeenSessionId() {
  sessionStorage.removeItem(session_key)
}

export function getKeenSessionId() {
  return sessionStorage.getItem(session_key)
}

export function setKeenSessionId() {
  let keenSessionId = sessionStorage.getItem(session_key)
  if (!keenSessionId) {
    // timestamp + a random 4 alphanumeric string in case of users accessing at precisely same millisecond
    keenSessionId = Date.now().toString() + Math.random().toString(36).slice(9)
    sessionStorage.setItem(session_key, keenSessionId)
  }
}


// todo - these two may not be necessary
export function putKeenSessionIdInLocalStorage() {
  const keenSessionId = sessionStorage.getItem(session_key)
  localStorage.setItem(session_key, keenSessionId)
}

export function clearKeenSessionFromLocalStorage() {
  localStorage.removeItem(session_key)
  localStorage.removeItem('azure-registration')
}
