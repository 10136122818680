import { T, always, cond, includes } from 'ramda'

export const getRoleQuestionResponse = (id, criteria) => {
  const intCriteria = criteria.map((c) => parseInt(c))
    const getRole = cond([
        [includes(1), always('Student')],
        [includes(2), always('Faculty')],
        [T, always(null)],
      ])
    const roleFromId = getRole([id])
    return getRole(intCriteria) ? getRole(intCriteria) : roleFromId
}