import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  history: [],
}

const historySlice = createSlice({
  initialState,
  name: 'history',
  reducers: {
    setHistory: (state, { payload }) => assoc('history', payload, state),
  },
})

export const { setHistory } = historySlice.actions

export default historySlice.reducer
