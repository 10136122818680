import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getData } from '../utils/api/getData'
import { isNil, not } from 'ramda'

const InitDataContext = React.createContext({})

/**
 * Example initData payload:
 * {
 *   school: {id: 1, name: school name, ...},
 *   categories: [{id: 1, name: 'physical safety', sort_order: 0}, ...]
 *   questions: [{id: 1, name: 'what is it you would say you do here?', type: 'role', ...}, ...]
 *   lookups: {emergency: {y: 'Yes', ...}, ...}
 * }
 *
 * @param children
 * @param domain
 * @returns {JSX.Element}
 * @constructor
 */
const InitDataContextProvider = ({ children, domain = null }) => {
  const [context, setContext] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (not(isNil(domain))) {
      setLoading(true)
      getData(`/v2/triage/init?org_domain=${domain}`)
        .then(res => setContext(res))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [domain])

  return (
    <InitDataContext.Provider value={{ ...context, loading }}>
      {children}
    </InitDataContext.Provider>
  )
}

InitDataContextProvider.propTypes = {
  children: PropTypes.any,
  domain: PropTypes.string,
}

const useInitDataContext = () => {
  const state = useContext(InitDataContext)
  if (state === undefined) {
    throw new Error('initData context used outside of its provider')
  }
  return state
}

export { InitDataContextProvider, InitDataContext, useInitDataContext }
