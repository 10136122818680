import { includes, not } from "ramda"
import trackEvent from "./trackEvent"


const emergencyPagePaths = [
  '/triage/emergency/them?criteria=1',
  '/triage/emergency/me?criteria=2',
  '/triage/emergency/them?criteria=1',
  '/triage/emergency/me?criteria=2',
]


const sendAnalytics = (page, buttonType, value, pageType = '') => {

  // const { trackEvent } = useMixpanelEvent()

  let myselfThemAnswer = ''

  if (!value) {
    return
  }

  if (value.id === 'me' || value.myself_other_answer === 'me') {
    myselfThemAnswer = 'Me'
  }

  if (value.id === 'them' || value.myself_other_answer === 'them') {
    myselfThemAnswer = 'Someone Else'
  }

  if (page === '/triage') {
    trackEvent('HC Emergency Screen', {
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': buttonType === 'single-select' ? value.id : null,
    })
  }

  if (page === '/triage/non-emergency') {
    trackEvent('HC Myself Other Screen', {
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Not an Emergency',
        'Myself Other Answer': myselfThemAnswer,
    })
  }

  if (
    page === '/triage/non-emergency/them' ||
    page === '/triage/non-emergency/me' 
  ) {
    trackEvent('HC Role Screen Non Emergency', {
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Not an Emergency',
        'Myself Other Answer': value.myself_other_answer,
        'Role Answer': value.role_answer,
    })
  }

  const getNeedsAndCategories = (needs) => {
    const activeNeeds = []
    let activeCategories = []
    const existingNeeds = needs ? needs : []
    for(const need of existingNeeds) {
      activeNeeds.push(need.name)
      activeCategories.push(need.category)
      activeCategories = [...new Set(activeCategories)]
    }
    return {activeCategories: activeCategories,
      activeNeeds: activeNeeds}
  }

  if (pageType === 'needs-page') {
    const needsAndCategories = getNeedsAndCategories(value.needs)

    trackEvent('HC Needs Screen', {
        'All Topics': value.all_topics ? value.all_topics : false,
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Not an Emergency',
        'Myself Other Answer': myselfThemAnswer,
        'Role Answer': value.role_answer,
        'Selected Categories': value.all_topics ? null : needsAndCategories.activeCategories,
        'Selected Needs': value.all_topics ? null : needsAndCategories.activeNeeds,
    })
  }

  if (pageType === 'multi-select-page') {
    const answers = []
    const valueAnswers = value.answers ? value.answers : []
    for (const answer of valueAnswers) {
        answers.push(answer.name)
    }

    const needsAndCategories = getNeedsAndCategories(value.needs)

    // Not coming over from keen
    trackEvent('HC Dynamic Questions Screen', {
        'All Topics': value.all_topics,
        'Answers': value.all_topics ? null : answers,
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Not an Emergency',
        'Myself Other Answer': myselfThemAnswer,
        Path: page,
        'Question Title': value.questionObject.name,
        'Role Answer': value.role_answer,
        'Selected Categories': needsAndCategories.activeCategories,
        'Selected Needs': needsAndCategories.activeNeeds,
    })
  }

  if (buttonType === 'single-select' && pageType === 'single-select-page' && not(includes(page, ['/triage', '/triage/emergency', '/triage/emergency/me', '/triage/emergency/them', '/triage/non-emergency', '/triage/non-emergency/me', '/triage/non-emergency/them']))) {
    trackEvent('HC Single Select Questions Screen', {
        'Answer': value.name,
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': value.questionObject.type,
        'Myself Other Answer': myselfThemAnswer,
        Path: page,
        'Question Title': value.questionObject.name,
        'Role Answer': value.role_answer,
    })
  }

  if (page === '/triage/emergency') {
    trackEvent('HC Myself Other Screen Emergency', {
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Emergency',
        'Myself Other Answer': buttonType === 'single-select' ? myselfThemAnswer : null,
    })
  }

  if (page === '/triage/emergency/them' || page === '/triage/emergency/me') {
    trackEvent('HC Role Screen Emergency', {
        'Back Nav': buttonType === 'back-nav',
        'Emergency Answer': 'Emergency',
        'Myself Other Answer': myselfThemAnswer,
        'Role Answer': buttonType === 'single-select' ? value.name : null,
    })
  }

  // Deprecated event
  if (emergencyPagePaths.includes(page)) {
    trackEvent('HC Type of Emergency Screen', {
        'Back Nav': buttonType === 'back-nav',
        emergency_type: buttonType === 'single-select' ? value.name : null,
    })
  }

  if (pageType === 'resource') {
    trackEvent('HC Resource Card', {
        Accesscriteria: value.accessCriteria,
        'Back Nav': buttonType === 'back-nav',
        'Card Needs': value.isEmergency ? null : value.needTitles,
        'Card Title': value.resource.title,
        'Card Type': value.resource.type,
        'Copy Link': buttonType === 'copy-link',
        'Element Name': value.text ?? null,
        'Element Url': value.link ?? null,
        'Emergency': value.isEmergency,
        'Featured Resource': value.resource.is_featured,
        'Location Attribute': value.resource.location_access_criterion ?? null,
        'Myself Other Answer': value.isHelper ? 'them' : 'me',
    })
  }

  if (pageType === 'resource-helper') {
    trackEvent('HC Resource Card Helper', {
        'Back Nav': buttonType === 'back-nav',
        'Card Title': 'Not sure how to help',
        'Card Type': 'helper',
        'Copy Link': buttonType === 'copy-link',
        'Emergency': value.isEmergency,
    })
  }

  if (page === 'footer') {
    trackEvent('HC Footer', {
        'Click Element': value.click_element,
        'Link Url': value.link_url,
        Path: value.path
    })
  }

  if (pageType === 'resources-page') {
    const needsAndCategories = getNeedsAndCategories(value.needs)
    trackEvent('HC Resources Page', {
        'All Topics': value.all_topics,
        'Copy Link': buttonType === 'copy-link',
        Emergency: value.isEmergency,
        'Myself Other Answer': value.isHelper ? 'them' : 'me',
        Path: value.path,
        'Role Answer': value.role_answer,
        'Selected Categories': needsAndCategories.activeCategories,
        'Selected Needs': needsAndCategories.activeNeeds,
        'Start New Search': buttonType === 'start-new-search',
        'You Link': buttonType === 'you-link',
    })
  }
}

export default sendAnalytics
