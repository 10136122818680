import Question from '../../../components/Question/Question'
import { QUESTION_TYPE_SINGLESELECT } from '../../../utils/questionTypes'

const EmergencyPage = school => {
  // this means we are still gathering info about the user
  const progressStep = 0

  return (
    <Question
      progressStep={progressStep}
      questionObject={{
        access_criteria: [
          { id: 'me', name: 'Me' },
          { id: 'them', name: 'Someone Else' },
        ],
        id: null,
        name: 'Do you need help for yourself or someone else?',
        why_asking:
          "Sometimes it's not easy to talk to others about seeking help, if you select someone else, we will include tips on managing conversations around offering help.",
      }}
      triage_logo={school.flagImage}
      type={QUESTION_TYPE_SINGLESELECT}
    />
  )
}

export default EmergencyPage
