const SCHOOL_CONFIG_KEY = 'school_config'

/**
 * @param schoolConfig
 *
 * Explanation of the business logic below and where it is used.
 *
 * We originally were going to pull this all from a redis cache, however there are some caveats to that approach
 * 1. if we have a FE cache, it's harder to validate it's correct and change it from the BE.
 * 2. if we try to give the FE access to the BE redis, we have to do so with a hardcoded link, this link changes all
 *    the time due to how heroku manages the redis containers. Thus, the link could be out of date at any point
 * 3. there are three things that are hard in programming, cache validation and off by one errors :)
 *
 * I swapped to this system of using the session storage for these reasons:
 * 1. it deletes itself on tab close, so we don't have to worry about clean up of data
 * 2. we can get the most recent school config and store it at server run time of our next application
 *      - this means that users will always get the most up to date school colors and such
 *      - we can have multiple schools on the same box still without worrying about environment variables
 * 3. if we need to pivot to local storage for speed, it's really easy
 * 4. it plays well with the getInitialProps in _app as well as the route change state reloads
 *
 * This stores all the config information for a school that it needs to set up the theme object, the SSO links, and
 * the metadata for SEO. These values shouldn't change all that often, so it's safe enough to store them at initial load
 * and not worry about it after that.
 *
 * This info used to be stored in either environment variables, or was built as part of the old webpack build scripts
 * but is now passed directly into the theme provider in _app and the backendApiContext for access.
 *
 * schoolConfig is passed as an object
 *
 * Stan 8/26/2022
 */

export const setSchoolConfig = (schoolConfig) => {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    sessionStorage.setItem(SCHOOL_CONFIG_KEY, JSON.stringify(schoolConfig))
  }
}

export async function getSchoolConfigAsync() {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    return JSON.parse(sessionStorage.getItem(SCHOOL_CONFIG_KEY))
  }
  return false
}

export function getSchoolConfig() {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    return JSON.parse(sessionStorage.getItem(SCHOOL_CONFIG_KEY))
  }
  return false
}

export const clearSchoolConfig = () => {
  if (typeof window !== 'undefined') {
    // make sure we can access session storage
    sessionStorage.removeItem(SCHOOL_CONFIG_KEY)
  }
}
