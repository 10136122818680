import mixpanel from 'mixpanel-browser'
import { equals } from 'ramda'
import { useEffect, useState } from 'react'
import useMixpanelEvent from '../../hooks/useMixpanelEvent'

const MixpanelInitializer = () => {
  const { trackEvent } = useMixpanelEvent()
  const [visitTime, setVisitTime] = useState(null)

  mixpanel.init(process.env.REACT_APP_PUBLIC_MIXPANEL_TOKEN, {
    debug: equals(process.env.NODE_ENV, 'development'),
    persistence: 'localStorage',
    track_pageview: false,
  })

  useEffect(() => {
    setVisitTime(Math.floor(Date.now() / 1000))
  }, [])

  // useEffect(() => {
  //   setTimeout(trackEvent('Session Start'), 2000)
  //   window.onbeforeunload = () =>
  //     trackEvent('Session End', {
  //       'Time on Site': Math.floor(Date.now() / 1000) - visitTime,
  //     })
  // }, [])

  return
}

export default MixpanelInitializer
