import palette from '../palette'

export const MuiAccordionSummary = {
  styleOverrides: {
    root: {
      '&.Mui-expanded': {
      backgroundColor: palette.surface['container-high'],
      borderRadius: '16px 16px 0 0',
      }
    }
  }
}
