/* eslint-disable sort-keys */
import YouLogoBadge from '../YouLogoBadge'
import PropTypes from 'prop-types'
import { getOrgDomain } from '../../utils/sessionStorage/orgDomain'
import { getSchoolConfig } from '../../utils/sessionStorage/schoolConfig'
import parse from 'html-react-parser'
import { Box, Stack, Container, Typography, Grid } from '@mui/material'

const YouResource = ({ resource }) => {
  const orgDomain = getOrgDomain()
  const school = getSchoolConfig()

  return (
    <Container sx={{ p: { xs: 0, lg: 'inherit' } }}>
      <Box
        alt={resource.title + ' Image'}
        component="image"
        src={resource.image_url}
        style={{ borderRadius: '8px', width: '100%' }}
      />
      <Stack spacing={2} sx={{ mb: 15, mt: 2 }}>
        <Typography component="h2" variant="h3">
          {resource.title}
        </Typography>
        <Typography variant="body2">{parse(resource.summary)}</Typography>
        <Typography variant="body2">{parse(resource.page_content)} </Typography>
        <Typography variant="body2">
          {parse(resource.video_description)}
        </Typography>

        <Grid container>
          <Grid item sm={6} sx={{ p: 2 }} xs={12}>
            <YouLogoBadge
              schoolLogo={school.logoImage}
              sx={{ borderRadius: '8px' }}
            />
          </Grid>
          <Grid item sm={6} sx={{ p: 2 }} xs={12}>
            <Typography variant="caption">
              This resource was originally featured on {school.name}.&nbsp;
              <a
                href={'https://' + orgDomain + '/' + resource.link}
                rel="noreferrer"
                target="_blank"
              >
                See the original article
              </a>
              &nbsp; or &nbsp;
              <Box component="Link" href="/triage">
                Find more resources like this to support your well-being.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}

YouResource.propTypes = {
  resource: PropTypes.object,
}

export default YouResource
