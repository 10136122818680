export const typography = palette => ({
    body1: {
      '@media (max-width:600px)': {
        fontSize: '0.75rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: 1.4
    },
    body2: {
      '@media (max-width:600px)': {
        fontSize: '0.625rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0.25,
      lineHeight: 1.4
    },
    caption: {
      '@media (max-width:600px)': {
        fontSize: '0.625rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: 1.35
    },
    displayTitle: {
      '@media (max-width:600px)': {
        fontSize: '2rem',
        letterSpacing: -0.48
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '3rem',
      fontWeight: 600,
      letterSpacing: -0.72,
      lineHeight: 1,
      textTransform: 'capitalize'
    },
    displaySubtitle: {
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
        letterSpacing: '-0.12px'
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: '-1px',
      lineHeight: 'normal',
    },
    h1: {
      '@media (max-width:600px)': {
        fontSize: '2rem',
        letterSpacing: -0.48
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '3rem',
      fontWeight: 500,
      letterSpacing: -0.72,
      lineHeight: 1,
      textTransform: 'capitalize'
    },
    h2: {
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
        letterSpacing: '-0.12px'
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: '-0.16px',
      lineHeight: 1,
    },
    h3: {
      '@media (max-width:600px)': {
        fontSize: '1.25rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.2
    },
    h4: {
      '@media (max-width:600px)': {
        fontSize: '1rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0.25,
      lineHeight: 1.2
    },
    h5: {
      '@media (max-width:600px)': {
        fontSize: '0.875rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.2
    },
    h6: {
      '@media (max-width:600px)': {
        fontSize: '0.75rem',
        letterSpacing: 0.018
      },
      color: 'surface.on-surface',
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: 0.021,
      lineHeight: 1.2
    },
    needTag: {
      fontFamily: 'aktiv-grotesk, sans serif',
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
      lineHeight: '19px',
    },
    overline: {
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: 0.5,
      lineHeight: 1.33333,
      textTransform: 'uppercase'
    },
    pageTitle: {
      '@media (max-width:600px)': {
        fontSize: '2rem'
      },
      color: 'surface.on-surface',
      fontFamily: 'gelica, sans-serif',
      fontSize: '3rem',
      fontWeight: 600,
      letterSpacing: -1,
      lineHeight: 1,
      textTransform: 'capitalize'
    },
    screenReaderOnly: {
      WebkitClipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
      clip: 'rect(1px,1px,1px,1px)',
      clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      width: '1px'
    },
    subtitle1: {
      '@media (max-width:600px)': {
        fontSize: '0.75rem'
      },
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: 1.57143
    },
    subtitle2: {
      '@media (max-width:600px)': {
        fontSize: '0.625rem'
      },
      fontFamily: 'aktiv-grotesk, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: 0.1,
      lineHeight: 1.833
    },  
})
