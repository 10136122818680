import { getSchoolConfig } from '../../../../../utils/sessionStorage/schoolConfig'
import Question from '../../../../../components/Question/Question'
import { useInitDataContext } from '../../../../../contexts/initDataContext'
import getQuestionType from '../../../../../utils/getQuestionType'
import { useState, useEffect } from 'react'
import { findIndex, gte, inc, length, lt, propEq } from 'ramda'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

const NonEmergencyQuestionDisplay = () => {
  const school = getSchoolConfig()
  const [searchParams] = useSearchParams()
  const { question } = useParams()
  const location = useLocation()
  // you answer the "who needs help?" question in the [person] index route. So we can assume we are on the
  // second progress step if we make it to this component
  const progressStep = 2
  const { flow, categories } = useInitDataContext()

  // flow for non emergency questions
  const [currentFlow, setCurrentFlow] = useState(flow?.nonEmergency)

  // set in use effect because not available initially
  useEffect(() => {
    setCurrentFlow(flow?.nonEmergency)
  }, [flow])

  // short circuit if questions are null
  if (currentFlow == null) return null

  const questionIndex = findIndex(propEq(parseInt(question), 'id'), currentFlow)
  const nextIndex = inc(questionIndex)

  const criteria = searchParams.get('criteria')
    ? searchParams.get('criteria').split(',')
    : []

  // TODO: Fix this logic, now that needs question is a question in the URL this logic
  // needs to be reworked. not sure how to identify if
  const roleQuestionParam = criteria.length ? parseInt(criteria[0]) : null

  const categoriesFiltered = categories.map(category => {
    //Filter Categories based on Role
    const categoryClone = Object.assign({}, category)
    if (roleQuestionParam) {
      categoryClone.needs = category.needs.filter(need => {
        return need.role_dependencies.includes(roleQuestionParam)
      })
    }
    return categoryClone
  })

  const splitRouterPath = location.pathname.split('/')

  const needsAnalytics = {
    // the who question is the last route with no dynamic question or the second to last with one
    // the urls are set up (me, them).
    myself_other_answer:
      splitRouterPath.length === 5 ? splitRouterPath[3] : splitRouterPath.pop(),
    role_answer: roleQuestionParam == 1 ? 'Student' : 'Faculty',
  }

  return (
    <Question
      categories={categoriesFiltered}
      goToResources={gte(nextIndex, length(currentFlow))}
      isAccessCriteriaQuestion={true}
      needsAnalytics={needsAnalytics}
      nextQuestionId={
        lt(nextIndex, length(currentFlow)) ? currentFlow[nextIndex].id : null
      }
      progressStep={progressStep}
      questionObject={currentFlow.find(q => q.id === parseInt(question))}
      school={school}
      triage_logo={school.flagImage}
      type={getQuestionType(currentFlow[questionIndex])}
    />
  )
}

export default NonEmergencyQuestionDisplay
