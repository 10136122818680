import React from 'react'
import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import sendAnalytics from '../utils/sendAnalytics'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import { Modal, Stack } from '@mui/material'

const CopyLinkButton = ({ resourceAnalytics }) => {
  const [clicked, setClicked] = useState(false)

  const handleCopyLink = () => {
    sendAnalytics(
      window.location,
      'copy-link',
      { ...resourceAnalytics },
      resourceAnalytics.pageType
    )
    navigator.clipboard.writeText(window.location.href)
    setClicked(true)
  }

  const style = {
    alignItems: 'center',
    bgcolor: 'surface.container',
    borderRadius: '16px',
    boxShadow: 24,
    left: '50%',
    p: 4,
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
  }

  return (
    <>
      <Modal open={clicked}>
        <Stack spacing={3} sx={style}>
          <Typography variant="h2">Copied!</Typography>
          <Typography variant="body1">
            This unique link contains all of your results so that you can
            revisit them any time, and/or share these resources with others.
            Save or send this link to access this resource at a later date.
          </Typography>
          <Typography variant="body1">
            <strong>Helping someone else?</strong> Explore{' '}
            <a href={'/helper'}>helpful tips</a> for offering support!
          </Typography>
          <Button onClick={() => setClicked(false)} variant="home">
            Close
          </Button>
        </Stack>
      </Modal>
      <Button onClick={() => handleCopyLink()} variant="copy-link">
        <Icon sx={{ mr: 1 }}>link</Icon>
        <Typography variant="body2">Copy Link</Typography>
      </Button>
    </>
  )
}

CopyLinkButton.propTypes = {
  resourceAnalytics: PropTypes.object,
}

export default CopyLinkButton
