import Typography from '@mui/material/Typography'
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
// import LocationCityIcon from '@mui/icons-material/LocationCity'
// import CloudIcon from '@mui/icons-material/Cloud'
import PropTypes from 'prop-types'
import { always, cond, equals } from 'ramda'
import Icon from './Icon/Icon'

const LocationBadge = ({ as, location }) => {
  const chooseIcon = cond([
    [
      equals('Virtual'),
      always(
        <Icon fill key={'virtual'} sx={{ fontSize: 14, mr: 1 }}>
          cloud
        </Icon>
      ),
    ],
    [
      equals('Off-Campus'),
      always(
        <Icon fill key={'off-campus'} sx={{ fontSize: 14, mr: 1 }}>
          location_city
        </Icon>
      ),
    ],
    [
      equals('On-Campus'),
      always(
        <Icon fill key={'on-campus'} sx={{ fontSize: 14, mr: 1 }}>
          location_on
        </Icon>
      ),
    ],
  ])

  return (
    <Typography
      component={as ? as : 'span'}
      key={location}
      sx={{
        alignItems: 'center',
        backgroundColor: 'surface.container-high',
        borderRadius: 50,
        display: 'flex',
        p: 0.5,
        width: 'fit-content',
      }}
      variant="body2"
    >
      {chooseIcon(location)}
      {location}
    </Typography>
  )
}

LocationBadge.propTypes = {
  as: PropTypes.string,
  location: PropTypes.string,
}

export default LocationBadge
