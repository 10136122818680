import { append, dropLast, gte, join, length, split } from 'ramda'


export const getFormattedResourcesPath = (currentPath) => {
    if(gte(length(split('/', currentPath)), 5)) {
        return join(
            '/',
            append('resources', dropLast(1, split('/', currentPath)))
          )
    } else {
        return currentPath + '/resources'
    }
}