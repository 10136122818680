import React, { useState, useContext, useEffect } from 'react'
import WhyAmIBeingAskedThis from '../WhyAmIBeingAskedThis'
import PropTypes from 'prop-types'
import Grid2 from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import { pluck, filter, not, isEmpty, equals } from 'ramda'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'
import SelectAllInput from '../SelectAllInput'
import { QuestionContext } from '../../../contexts/questionContext'
import { useLocation } from 'react-router-dom'

const MultiSelectQuestion = ({
  questionDescription,
  questionObject,
  searchParams,
  // setSearchParams,
}) => {
  const qs = require('qs')
  const { previousAnswers, setPreviousAnswers } = useContext(QuestionContext)
  const [selected, setSelected] = useState(
    previousAnswers?.[questionObject.id]?.answers
      ? previousAnswers[questionObject.id].answers
      : []
  )

  const location = useLocation()

  useEffect(() => {
    // if we have previous answers for this question, we need to add them to the
    // query so the next button press will update the route properly
    if (previousAnswers?.[questionObject.id]?.answers) {
      reassignQueryMultiselectObject(
        questionObject,
        pluck('id', previousAnswers[questionObject.id].answers)
      )
    }
  }, [])

  const [selectAll, setSelectAll] = useState(false)

  //Handlers////////////////////////////////////////////////////////////////////
  const handleSelectAllChange = () => {
    // if selected, add all criteria to the selected list
    const selectedCriteria = []

    if (!selectAll) {
      // selected all resources
      // adds all criteria from this question to the list
      questionObject.access_criteria.map(criteria => {
        selectedCriteria.push(criteria)
      })
    }

    setSelected(selectedCriteria)

    reassignQueryMultiselectObject(
      questionObject,
      pluck('id', selectedCriteria)
    )

    // set selections in context with selectAll
    setPreviousAnswers({
      ...previousAnswers,
      [questionObject.id]: {
        // answers the user selected
        answers: [...selectedCriteria],
        // selectAll property indicating whether the user checked the select all topics box or not
        selectAll: !selectAll,
      },
    })
    setSelectAll(!selectAll)
  }

  const onChange = criteria => {
    // Make sure select all is set to false
    setSelectAll(false)

    // first we need to make sure that the selected list only has criteria
    // pertaining to this question
    const filtered_selected = selected.filter(
      ({ question_id }) => question_id == criteria.question_id
    )

    let newCriteria = filtered_selected

    if (containsCriteria(criteria.id)) {
      newCriteria = filtered_selected.filter(({ id }) => id != criteria.id)
    } else {
      newCriteria = [...filtered_selected, criteria]
    }

    // update selected criteria in state
    setSelected(newCriteria)

    // add or remove from selected and reset state
    const selectedIds = newCriteria.map(criteria => {
      return criteria.id
    })

    // set selections in context
    setPreviousAnswers({
      ...previousAnswers,
      [questionObject.id]: {
        answers: newCriteria,
        selectAll: false,
      },
    })

    console.log('selected', selected)

    // map the criteria to the router query and then apply them to the route
    reassignQueryMultiselectObject(questionObject, selectedIds)
  }

  /**
   * Updates the router query multiselect object by parsing it from the
   * query, updating the object, and then re encoding and re setting it
   * @param {*} question current question object
   * @param {*} answerIds selected ids of criteria for question
   */
  const reassignQueryMultiselectObject = (question, answerIds) => {
    const multi_query_obj = qs.parse(searchParams.get('multiselect'))
    multi_query_obj[question.id] = answerIds
    searchParams.set('multiselect', qs.stringify(multi_query_obj))
    // setSearchParams(searchParams)
  }

  const containsCriteria = val =>
    not(isEmpty(filter(({ id }) => equals(id, val), selected)))

  return (
    <Grid2 container spacing={2}>
      <Grid2 item size={{ xs: 10 }} sx={{ pl: 1.5 }}>
        <Box>
          <Stack spacing={2}>
            <Typography component={'h2'} variant="h2">
              {location.pathname.includes('/them') && questionObject.them_name
                ? questionObject.them_name
                : questionObject.name}
            </Typography>
            {questionDescription && (
              <Typography variant="body3">{questionDescription}</Typography>
            )}
            <WhyAmIBeingAskedThis questionObject={questionObject} />
            <Typography variant="body1">Select all that apply</Typography>
            <Stack alignItems={'flex-end'}>
              {questionObject.access_criteria.map((criteria, index) => (
                <Chip
                  id={index}
                  key={index}
                  label={criteria.name}
                  onClick={() => onChange(criteria)}
                  sx={
                    containsCriteria(criteria.id)
                      ? {
                          ':focus': {
                            backgroundColor: 'palette.surface.container-high',
                          },
                          backgroundColor: 'buttons.tonal.dark.on-surface',
                          border: 'none',
                          borderRadius: '8px',
                          fontFamily: 'aktiv-grotesk, sans-serif',
                          fontSize: 14,
                          fontWeight: 400,
                          mb: 4,
                          mr: 1,
                        }
                      : {
                          ':focus': {
                            backgroundColor: 'palette.surface.container-high',
                          },
                          backgroundColor: 'surface.container-low',
                          border: 'none',
                          borderRadius: '8px',
                          fontFamily: 'aktiv-grotesk, sans-serif',
                          fontSize: 14,
                          mb: 4,
                          mr: 1,
                        }
                  }
                  variant={
                    containsCriteria(criteria.id) ? 'filled' : 'outlined'
                  }
                />
              ))}
            </Stack>
          </Stack>
        </Box>
        <SelectAllInput
          handleSeeAllTopicsChange={handleSelectAllChange}
          label="Select All Options"
          selectAll={selectAll}
          tooltip="If you select Select All Options, your results will be filtered to match all available choices. You may click Next without a selection to advance without filtering."
        />
      </Grid2>
    </Grid2>
  )
}

MultiSelectQuestion.propTypes = {
  questionDescription: PropTypes.string,
  questionObject: PropTypes.shape({
    access_criteria: PropTypes.array,
    id: PropTypes.number,
    name: PropTypes.string,
    them_name: PropTypes.string,
  }),
  searchParams: PropTypes.object,
}

export default MultiSelectQuestion
