import { getSchoolConfig } from '../../../../utils/sessionStorage/schoolConfig'
import Question from '../../../../components/Question/Question'
import { useInitDataContext } from '../../../../contexts/initDataContext'
import getQuestionType from '../../../../utils/getQuestionType'
import { useState, useEffect } from 'react'

const NonEmergencyPersonPage = () => {
  const school = getSchoolConfig()
  const progressStep = 1
  const { flow, categories } = useInitDataContext()

  // non emergency question flow
  const [currentFlow, setCurrentFlow] = useState(flow?.nonEmergency)

  // have to set this in a useEffect because it isn't there initially
  useEffect(() => {
    setCurrentFlow(flow?.nonEmergency)
  }, [flow])

  // short circuit if questions are null
  if (currentFlow == null) return null

  // since this is the first question in the list always (due to the routing), we can safely assume
  // that the questionIndex is 0 for this component. in [question] it is dynamic
  const firstQuestion = currentFlow[0]

  const nextQuestion = currentFlow[1]

  return (
    <Question
      categories={categories}
      goToResources={currentFlow.length === 1}
      isAccessCriteriaQuestion={true}
      nextQuestionId={nextQuestion?.id}
      progressStep={progressStep}
      questionObject={firstQuestion}
      school={school}
      triage_logo={school.flagImage}
      type={getQuestionType(firstQuestion)}
    />
  )
}

export default NonEmergencyPersonPage
