import palette from '../palette'

export const MuiAccordionDetails = {
  styleOverrides: {
    root: {
      backgroundColor: palette.surface['container-high'],
      borderRadius: '0 0 16px 16px',
    }
  }
}
